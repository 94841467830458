:global {
  :root {
    --color-violet-primary: #623cea;
    --color-blue-primary: #1182f6;
    --color-green-primary: #34cb73;
    --color-red-primary: #f53149;
    --color-yellow-primary: #ffd600;
    --color-violet-light: #8d6ff8;
    --color-blue-light: #5faeff;
    --color-lightblue-primary: #ddf1fe;
    --color-green-light: #61dc94;
    --color-red-light: #fe7888;
    --color-yellow-light: #ffeb80;
    --color-violet-medium: #502dcc;
    --color-blue-medium: #1a5ecf;
    --color-green-medium: #1ca254;
    --color-red-medium: #cc1e33;
    --color-yellow-medium: #ffb800;
    --color-violet-dark: #3616a8;
    --color-blue-dark: #05398f;
    --color-green-dark: #057031;
    --color-red-dark: #970d1e;
    --color-yellow-dark: #dd8500;
    --color-yellow-20: rgba(255, 214, 0, 0.2);
    --color-green-20: rgba(52, 203, 115, 0.2);
    --color-red-20: rgba(245, 49, 73, 0.2);
    --color-violet-15: rgba(98, 60, 234, 0.15);
    --color-blue-5: rgba(17, 130, 246, 0.05);
    --color-blue-15: rgba(17, 130, 246, 0.15);
    --color-green-15: rgba(52, 203, 115, 0.15);
    --color-red-15: rgba(245, 49, 73, 0.15);
    --color-yellow-15: rgba(255, 214, 0, 0.15);
    --color-violet-10: rgba(98, 60, 234, 0.1);
    --color-blue-10: rgba(17, 130, 246, 0.1);
    --color-green-10: rgba(52, 203, 115, 0.1);
    --color-red-10: rgba(245, 49, 73, 0.1);
    --color-yellow-10: rgba(255, 214, 0, 0.1);
    --color-grey-dark: #71809c;
    --color-grey-dark-20: rgba(113, 128, 156, 0.2);
    --color-grey-dark-40: rgba(113, 128, 156, 0.2);
    --color-grey-medium: #dfe9f9;
    --color-grey-light: #f3f8ff;
    --color-black-primary: #161938;
    --color-black-light: #2d304c;
    --color-black-90: rgba(22, 25, 56, 0.9);
    --color-black-80: rgba(22, 25, 56, 0.8);
    --color-black-70: rgba(22, 25, 56, 0.7);
    --color-black-60: rgba(22, 25, 56, 0.6);
    --color-white-primary: #fff;
    --color-white-70: rgba(255, 255, 255, 0.7);
    --color-white-60: rgba(255, 255, 255, 0.6);
    --color-white-40: rgba(255, 255, 255, 0.4);
    --color-white-20: rgba(255, 255, 255, 0.2);
    --color-white-10: rgba(255, 255, 255, 0.1);

    --gradient-silence: linear-gradient(90deg, #1182f6 0%, #a933d3 100%);
    --gradient-village: linear-gradient(90deg, #34cb73 0%, #1182f6 100%);
    --gradient-sun: linear-gradient(90deg, #ffd600 0%, #ff9900 100%);
    --gradient-party: linear-gradient(
      90deg,
      #f53149 0%,
      #c250a0 49.48%,
      #8d6ff8 100%
    );
    --gradient-sea: linear-gradient(
      85.62deg,
      #1a5ecf 2.71%,
      #1182f6 52.06%,
      #5faeff 97.45%
    );

    --gradient-silence-10: linear-gradient(
      90deg,
      rgba(17, 130, 246, 0.1) 0%,
      rgba(169, 51, 211, 0.1) 100%
    );
    --gradient-village-10: linear-gradient(
      90deg,
      rgba(52, 203, 115, 0.1) 0%,
      rgba(17, 130, 246, 0.1) 100%
    );
    --gradient-sun-10: linear-gradient(
      90deg,
      rgba(255, 214, 0, 0.1) 0%,
      rgba(255, 153, 0, 0.1) 100%
    );
    --gradient-party-10: linear-gradient(
      90deg,
      rgba(245, 49, 73, 0.1) 0%,
      rgba(194, 80, 160, 0.1) 49.48%,
      rgba(141, 111, 248, 0.1) 100%
    );
    --gradient-sea-10: linear-gradient(
      85.62deg,
      rgba(26, 94, 207, 0.1) 2.71%,
      rgba(17, 130, 246, 0.1) 52.06%,
      rgba(95, 174, 255, 0.1) 97.45%
    );

    --shadow-elevation-1: 0px 1px 22px rgba(22, 53, 122, 0.09),
      0px 1px 4px rgba(22, 53, 122, 0.06), 0px 0px 1px rgba(22, 53, 122, 0.02);
    --shadow-elevation-2: 0px 6px 31px rgba(22, 53, 122, 0.06),
      0px 2px 7px rgba(22, 53, 122, 0.03), 0px 1px 2px rgba(22, 53, 122, 0.02);
    --shadow-elevation-3: 0px 9px 34px rgba(22, 53, 122, 0.08),
      0px 7px 18px rgba(22, 53, 122, 0.02),
      0px 10px 11px rgba(22, 53, 122, 0.02);
    --shadow-elevation-4: 0px 15px 40px rgba(22, 53, 122, 0.07),
      0px 14px 50px rgba(22, 53, 122, 0.05),
      0px 26px 43px rgba(22, 53, 122, 0.02);
    --shadow-elevation-5: 0px 31px 64px rgba(22, 53, 122, 0.05),
      0px 45px 67px rgba(22, 53, 122, 0.08),
      0px 34px 60px rgba(22, 53, 122, 0.04);

    --color-blue-32: rgba(17, 130, 246, 0.32);
    --color-green-32: rgba(52, 203, 115, 0.32);
    --color-grey-light-32: rgba(243, 248, 255, 0.32);
  }
}
