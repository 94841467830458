.section {
  display: flex;
  flex-flow: column nowrap;
  padding: 8px 16px;
}

.option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.optionCheck {
  margin-right: 8px;
}

.optionText {
  color: #5c738c;
}

.empty {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.emptyIcon {
  color: #5c738c;
}

.emptyText {
  flex: 1;
  margin-left: 8px;
  color: #5c738c;
}
