.header {
    display: flex;
    flex-flow: row nowrap;
    padding: 12px 16px;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grey-medium);
  }
  
  .date {
    color: #5C738C;
    width: 150px;
    display: flex;
    justify-content: flex-end;
  }
  
  .content {
    padding: 16px;
  }