.leftText {
  margin-right: 16px;
}

.lessonRow {
  margin-left: 72px;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 40px;
}
