.section {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.preview {
  width: 212px;
  height: 212px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe9f9;
  border-radius: 12px;
}

.previewImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  margin-left: 16px;
}

.text {
  margin-top: 16px;
}

.info {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  margin-left: 16px;
}

.infoText {
  margin-top: 16px;
}
