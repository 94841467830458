.card {
  margin-top: 8px;
  padding: 16px;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  border-radius: 16px;
}

.titleCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.statusCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  width: 40px;
  height: 40px;
}

.title {
  margin-left: 16px;
}
