.toast {
  margin-top: 12px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  padding: 12px;
  cursor: pointer;
  max-width: 500px;
}

.iconWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.icon {
  width: 20px;
  height: 20px;
}

.toastSuccess {
  .iconWrapper {
    background-color: rgba(52, 203, 115, 0.1);
  }

  .icon {
    color: #34cb73;
  }
}

.toastWarning {
  .iconWrapper {
    background-color: rgba(252, 174, 47, 0.1);
  }

  .icon {
    color: #fcae2f;
  }
}

.toastInfo {
  .iconWrapper {
    background-color: rgba(17, 130, 246, 0.1);
  }

  .icon {
    color: #1182f6;
  }
}

.toastError {
  .iconWrapper {
    background-color: rgba(245, 49, 73, 0.1);
  }

  .icon {
    color: #f53149;
  }
}

.inner {
  margin: 0 12px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.title {
  margin-bottom: 2px;
  color: #021930;
  letter-spacing: 0.15px;
}

.text {
  color: #5c738c;
  letter-spacing: 0.15px;
}

.closeIcon {
  color: #889ab1;
  cursor: pointer;

  &:hover {
  }
}
