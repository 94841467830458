.modal {
  width: 498px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.title {
  color: #021930;
}

.text {
  color: #5c738c;
  margin-top: 16px;
}

.actions {
  align-self: flex-end;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.confirmAction {
  margin-left: 8px;
}
