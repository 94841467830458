.upload {
  width: 100%;
}

.files {
  margin-left: 8px;
}

.file {
  margin-top: 8px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dfe9f9;
  background-color: var(--color-white-primary);
  cursor: pointer;
}

.fileRemoveIcon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.fileIcon {
  width: 20px;
  height: 20px;
  color: #5c738c;
}

.fileInner {
  margin-left: 16px;
  flex: 1;
}

.fileName {
  color: #021930;
}

.fileSize {
  color: #5c738c;
}

.fileProgress {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 4px;
}

.fileProgressText {
  margin-left: 32px;
}

.addBtn {
  margin-top: 8px;
}
