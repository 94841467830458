.text {
  color: #021930;
}

.textOutside {
  color: #889ab1;
}

.textToday {
  color: #021930;
}

.textSelected {
  color: #fff;
}
