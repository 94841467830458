.drawer {
  width: 324px;
  top: 64px !important;
  border-right: 1px solid #dfe9f9;
}

.drawerHeader {
  padding: 6px 6px 6px 16px;
  border-bottom: 1px solid #dfe9f9;
}
