.section {
  padding: 8px 16px;
  border-bottom: 1px solid #dfe9f9;

  &:last-child {
    border-bottom: 0;
  }
}

.sectionOpened {
  .icon {
    transform: rotate(180deg);
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}

.headerInner {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.check {
  margin-right: 8px;
}

.title {
  color: #5c738c;
}

.icon {
  width: 20px;
  height: 20px;
  color: #5c738c;
  transition: transform 0.15s ease-out;
}

.inner {
  margin-top: 16px;
}
