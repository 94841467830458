.section {
  margin: 16px 1px 0 0;
  background-color: var(--color-white-primary);
  border: 1px solid var(--color-white-primary);
  border-radius: 16px;
  transition: border-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    background-color: #f7f9fd;
    border: 1px solid #dfe9f9;
  }

  &:active {
    background-color: #ecf2fb;
    border: 1px solid #dfe9f9;
  }
}

.readOnlySection {
  &:hover {
    background-color: var(--color-white-primary);
    border: 1px solid var(--color-white-primary);
  }

  &:active {
    background-color: var(--color-white-primary);
    border: 1px solid var(--color-white-primary);
  }
}

.sectionOpened {
  .icon {
    transform: rotate(180deg);
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
}

.readOnlyHeader {
  cursor: default;
}

.hint {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--color-violet-primary);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.hintIcon {
  width: 20px;
  height: 20px;
  color: var(--color-white-primary);
}

.title {
  flex: 1;
  margin-left: 16px;
  color: var(--color-black-primary);
}

.icon {
  color: #5c738c;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  cursor: pointer;
}

.inner {
  padding: 0 16px 20px 16px;
}
