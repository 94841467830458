.block {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dfe9f9;
}
