.itemWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 120px;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
}

.item {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  z-index: 1;
  width: 308px;
  margin-top: 16px;
  padding: 12px 12px 12px 20px;
  background-color: var(--color-white-primary);
  border: 1px solid var(--color-grey-medium);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: all 0.15s ease-out;

  &:hover {
    border-color: var(--color-blue-primary);
  }

  &:first-child {
    margin-top: 0;
  }
}

.readOnlyItem {
  cursor: default;
  &:hover {
    border: 1px solid var(--color-grey-medium);
  }
}

.itemText {
  width: 426px;
}

.itemImage {
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 120px;
  padding: 4px 16px 4px 4px;
}

.itemSuccess {
  border-color: var(--color-green-primary);
}

.itemError {
  border-color: var(--color-red-primary);
}

.text {
  color: var(--color-black-primary);
}

.img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  object-fit: contain;
}
