.select {
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.optionTrigger {
  width: 100%;
}

.option {
  height: 130px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #dfe9f9;
  border-radius: 12px;
  transition: background-color 0.15s ease-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
  }
}

.optionIcon {
  width: 32px;
  height: 32px;
  color: #1182f6;
}

.optionText {
  margin-top: 8px;
  color: #1182f6;
}
