.section {
  margin-top: 16px;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 16px;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
}

.label {
  color: #5c738c;
}

.value {
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 185px;
}

.fileAccess {
  margin-top: 0;
}

.action {
  margin-top: 10px;
}
