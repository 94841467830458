.text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--color-blue-primary);
}

.path {
  transition: all 0.15s ease-out;
}
