.control {
  position: relative;
}

.controlWithSpace {
  margin-bottom: 24px;
}

.controlVertical {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.controlHorizontal {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .label {
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.label {
  margin-bottom: 8px;
  color: #5c738c;
}

.labelRequired {
  &:after {
    content: '*';
    margin-left: 2px;
    color: #f53149;
  }
}

.error {
  position: absolute;
  top: 100%;
  left: 12px;
  margin-top: 4px;
}

.errorText {
  color: #f53149;
}
