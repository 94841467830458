.block {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.playerContainer {
  position: relative;
  width: 100%;
}

.video {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-2);

  video {
    border-radius: 8px;
  }
}

.progress {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 100%;
  height: 92px;
  padding: 0 24px 16px;
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.48) 0%,
    rgba(0, 0, 0, 0.475849) 6.67%,
    rgba(0, 0, 0, 0.462955) 13.33%,
    rgba(0, 0, 0, 0.440803) 20%,
    rgba(0, 0, 0, 0.409243) 26.67%,
    rgba(0, 0, 0, 0.368748) 33.33%,
    rgba(0, 0, 0, 0.320696) 40%,
    rgba(0, 0, 0, 0.267508) 46.67%,
    rgba(0, 0, 0, 0.212492) 53.33%,
    rgba(0, 0, 0, 0.159304) 60%,
    rgba(0, 0, 0, 0.111252) 66.67%,
    rgba(0, 0, 0, 0.0707574) 73.33%,
    rgba(0, 0, 0, 0.0391967) 80%,
    rgba(0, 0, 0, 0.0170448) 86.67%,
    rgba(0, 0, 0, 0.00415065) 93.33%,
    rgba(0, 0, 0, 0) 100%
  );
}

.progressBar {
  width: 100%;
  // height: 4px;
}

.thumb {
  background-color: var(--color-white-20);
}

.track {
  background-color: var(--color-blue-primary);
}

.controls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.leftControls,
.rightControls {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.icon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  color: var(--color-white-primary);
}

.timing {
  margin-left: 12px;
  color: var(--color-white-primary);
}

.volume {
  width: 56px;
}

.popover {
  background-color: var(--color-white-primary);
  border-radius: 8px;
  width: 156px;
  box-shadow: var(--shadow-elevation-1);
  transform: translateY(-8px);
}

.trigger {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  width: 66px;
  margin: 0 24px;
}

.rate {
  padding: 13px 16px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--color-blue-10);
  }
}

.activeRate {
  background-color: var(--color-blue-5);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 0px 2px 2px 0px;
    width: 2px;
    height: 100%;
    background-color: var(--color-blue-primary);
  }
}

.playSpeed {
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 16px;
  border-radius: 8px;
  padding: 6px;
}

.playSpeedActive {
  background-color: var(--color-white-10);
}

.playbackRate {
  color: var(--color-white-primary);
  margin-left: 4px;
}

.playbackRate {
  width: 42px;
  text-align: center;
}

.rateIcon {
  height: 20px;
  width: 20px;
  color: var(--color-white-primary);
}

.caption {
  margin-top: 12px;
  padding-left: 12px;
  border-left: 2px solid #dfe9f9;
  font-style: italic;
}
