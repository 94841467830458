.difficulty {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  width: 24px;
  height: 24px;
}

.difficultyEasy {
  .icon {
    width: 100%;
    height: 100%;
    color: var(--color-green-primary);
  }
}

.difficultyMedium {
  .icon {
    width: 100%;
    height: 100%;
    color: var(--color-yellow-primary);
  }
}

.difficultyHard {
  .icon {
    width: 100%;
    height: 100%;
    color: var(--color-red-primary);
  }
}
