.preview {
  display: flex;
  flex-flow: column nowrap;
  padding: 16px;
}

.inner {
  margin-top: 16px;
  display: flex;
  flex-flow: column nowrap;
}

.img {
  max-width: 100%;
  object-fit: contain;
  border: 8px solid #dfe9f9;
  border-radius: 8px;
}
