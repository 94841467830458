.wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: var(--color-red-primary);
    border-radius: 50%;
  }
  
  .icon {
    width: 20px;
    height: 20px;
    color: var(--color-white-primary);
  }
  