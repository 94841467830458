.upload {
  width: 88px;
  height: 88px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #623cea;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:not(.uploadWithValue):hover {
    background-color: #4e30bb;
  }

  &:hover {
    .actionOverlay {
      opacity: 1;
    }
  }
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.preview {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.icon {
  width: 24px;
  height: 24px;
  color: #fff;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.actionOverlay {
  opacity: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-black-60);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.action {
  color: #fff;
}
