.propertyReadOnly {
  width: max-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  padding: 4px;
}

.property {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 4px;
  border-radius: 8px;
}

.grid {
  display: flex;
  flex-flow: column nowrap;
  padding: 4px;
  border: 1px solid #dfe9f9;
  border-radius: 4px;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
}

.item {
  width: 18px;
  height: 18px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 18px;
  height: 18px;
  color: #5c738c;
}

.circle {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #dfe9f9;
}
