.error {
  margin-top: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  color: #fcae2f;
}

.title {
  margin-top: 12px;
  color: #021930;
}

.text {
  margin-top: 4px;
  color: #5c738c;
}

.button {
  margin-top: 12px;
}
