.page {
  width: 818px;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  background-color: #f2f6fd;
}

.items {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -4px;
  margin-top: 24px;
  margin-bottom: -8px;
}

.item {
  margin: 0px 4px 8px; 
}

.readOnlyItem {
  cursor: default;
  &:hover {
    background-color: #e7f2fe;  background-color: #fff;  }
}

.itemDragging {
  opacity: 0;
}

.itemDropped {
  opacity: 0;
}

.inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 12px;
  margin-top: 40px;
}

.column {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.columnTitle {
  margin-bottom: 8px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}