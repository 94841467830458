.step {
  margin: 0 24px;
}

.button {
  width: 100%;
}

.upload {
  margin-top: 8px;
}
