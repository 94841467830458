.column {
  display: flex;
  flex-flow: column nowrap;
}

.variants {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 16px;
}

.readOnlyVariant {
  margin-left: 40px;
}

.title {
  margin-left: 96px;
}

.variant {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 16px;
  padding-left: 56px;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    .variantMenu {
      opacity: 1;
    }
  }
}

.variantMenu {
  opacity: 0;
  margin-right: 8px;
}

.button {
  margin-left: 94px;
  margin-top: 16px;
}
