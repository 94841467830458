.layer {
  margin-top: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.15s ease-out;
  border-radius: 8px;

  &:first-child {
    margin-top: 0;
  }

  &:not(.layerCurrent):hover {
    background-color: var(--color-grey-light);

    .typeIcon,
    .name {
      color: var(--color-black-primary);
    }
  }
}

.layerCurrent {
  background-color: rgba(17, 130, 246, 0.1);

  .typeIcon {
    color: #021930;
  }

  .name {
    color: #021930;
  }
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.icon {
  margin-left: 8px;
  width: 24px;
  height: 24px;
  color: #5c738c;
  transition: transform 0.15s ease-out;
}

.iconOpen {
  transform: rotate(180deg);
}

.typeIcon {
  width: 24px;
  height: 24px;

  transition: color 0.15s ease-out;
}

.typeIconDefault {
  color: #5c738c;
}

.typeIconPurple {
  color: #623cea;
}

.name {
  margin-left: 8px;
  transition: color 0.15s ease-out;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nameDefault {
  color: #5c738c;
}

.namePurple {
  color: #623cea;
}

.info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.checkCircle {
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #34cb73;
}
