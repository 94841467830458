.progress {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.progressText {
  margin-left: 4px;
  color: #5c738c;
}
