.page {
  flex: 1;
  background-color: #f2f6fd;
}

.header {
  display: flex;
  flex-flow: row nowrap;
}

.sectionHeader {
  margin-top: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 16px;
  background-color: #fff;
  border: 1px solid #dfe9f9;
  border-radius: 16px;
}

.tabsInner {
  margin-top: 0;
}
