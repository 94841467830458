.page {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 16px;
}

.toolbar {
  background-color: var(--color-white-primary);
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-grey-medium);
}

.actions {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 48px 16px 16px 16px;
  margin-top: -32px;
  background: linear-gradient(180deg,
      rgba(251, 252, 254, 0) 16%,
      rgba(251, 252, 254, 1) 32%,
      #f2f6fd 100%);
}

.action {
  width: 100%;
  margin-top: 8px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;

  &:first-child {
    margin-top: 0;
  }
}

.text {
  margin-top: 16px;
  color: #5c738c;
  text-align: center;
}