.settings {
  padding: 24px;
  min-height: 300px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
}

.preview {
  margin-right: 24px;
}

.inner {
  flex: 1;
}

.title {
  margin-bottom: 24px;
  width: 100%;
}

.description {
  width: 100%;
  margin-top: 16px;
}

.difficulty {
  display: flex;
  flex-flow: column nowrap;
}

.difficultyTitle {
  margin-bottom: 8px;
  color: #5c738c;
}

.free {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.freeText {
  color: #5c738c;
  margin-right: 16px;
}
