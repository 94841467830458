.form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1;
  margin: 24px;
}

.actions {
  margin-top: 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.action {
  flex: 1;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
}

.section {
  margin-top: 8px;
}

.sectionTitle {
  margin-bottom: 8px;
}
