.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.autosaveLoading {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  margin-right: 32px;

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 32px;
    background-color: #dfe9f9;
    top: 50%;
    transform: translateY(-50%);
    right: -16px;
  }
}

.autosaveText {
  margin-left: 4px;
  color: #1182f6;
}

.lastUpdated {
  color: #5c738c;
  margin-right: 16px;
}

.statusSelect {
  margin: 0 16px;

  &:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 32px;
    background-color: #dfe9f9;
    top: 50%;
    transform: translateY(-50%);
    left: -16px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 32px;
    background-color: #dfe9f9;
    top: 50%;
    transform: translateY(-50%);
    right: -16px;
  }
}

.buttons {
  margin-left: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.button {
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
}

.contextButton {
  margin-left: 8px;
}

.buttonActive {
  background-color: var(--color-blue-10);
}

.moreBtn {
  margin-left: 8px;
}

.moreBtnActive {
  color: #021930;
  background-color: rgba(17, 130, 246, 0.1);
}

.moreMenu {
  margin-top: 8px;
}
