.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  position: relative;
  margin-top: 16px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 12px;
}

.infoCard {
  margin-top: 12px;
}

.card {
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.emptyCoursesState {
  flex: 1;
  // min-height: calc(100vh - 116px);
}

.emptyState {
  margin-top: 12px;
  min-height: calc(100vh - 206px);
}
