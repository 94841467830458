.section {
  display: flex;
  flex-flow: column nowrap;
  padding: 16px 16px 0 16px;
}

.initialState {
  display: flex;
  flex-flow: column nowrap;
  padding: 12px 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  &:not(.initialStateActive):hover {
    background-color: rgba(17, 130, 246, 0.05);
  }
}

.initialStateByReference {
  margin-bottom: 4px;
}

.initialStateActive {
  background-color: rgba(17, 130, 246, 0.1);
  border-color: rgba(17, 130, 246, 0.1);

  .initialStateText {
    color: #021930;
  }
}

.initialStateText {
  color: #5c738c;
}

.tasks {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 8px;
}

.task {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid #dfe9f9;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 4px;

  &:first-child {
    margin-top: 0;
  }

  &:not(.taskCurrent):hover {
    background-color: rgba(17, 130, 246, 0.05);
  }
}

.taskCurrent {
  background-color: rgba(17, 130, 246, 0.1);
  border-color: rgba(17, 130, 246, 0.1);

  .taskName {
    color: #021930;
  }
}

.taskInner {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.taskName {
  color: #5c738c;
}

.taskError {
  margin-top: 4px;
  color: #f53149;
}

.taskIcon {
  margin-left: 16px;
  width: 24px;
  height: 24px;
  color: #f53149;
}

.addTaskBtn {
  margin-top: 10px;
}
