.link {
  display: block;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.default {
  color: var(--color-blue-primary);

  &:hover {
    color: var(--color-blue-medium);
  }

  &:active {
    color: var(--color-blue-dark);
  }
}
