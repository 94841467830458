.codeCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.copyIcon {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: var(--color-blue-primary);
}

.titleCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.statusCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  width: 40px;
  height: 40px;
}

.title {
  margin-left: 16px;
}

.studentCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.studentInner {
  max-width: calc(100% - 55px);
  flex: 1;
  margin-left: 8px;
}

.studentEmail {
  margin-top: 4px;
  color: #5c738c;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
