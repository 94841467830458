.preview {
  width: 104px;
  height: 104px;
  border-radius: 12px;
}

.webPreview {
  width: 72px;
  height: 72px;
}

.tags {
  margin-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.trainingTags {
  margin-top: 0;
}

.tag {
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.title {
  margin-top: 8px;
  color: #021930;
}

.progress {
  margin-top: 8px;
}

.info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 8px;
}

.infoItem {
  padding: 0 4px;
  border-left: 1px solid #dfe9f9;

  &:first-child {
    padding-left: 0;
    border-left: 0;
  }
}

.lessonsLeftText {
  color: #5c738c;
}

.descriptionText {
  color: #5c738c;
  padding: 16px 0;
}

.courseInfo {
  margin-top: 8px;
}

.button {
  margin-top: 16px;
  width: 100%;
}

.webButton {
  width: auto;
}

