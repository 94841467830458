.inner {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 24px;
  background-color: #fff;
  border: 1px solid #dfe9f9;
  border-radius: 16px;
}

.tabs {
  overflow: visible;
}