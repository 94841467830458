.checkbox {
  position: relative;
  display: flex;
  flex-flow: colum nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:not(.checkboxDisabled):not(.checkboxReadOnly):hover {
    .iconWrapper {
      border-color: var(--color-blue-primary);
    }
  }

  &:not(.checkboxDisabled):not(.checkboxReadOnly):active {
    .iconWrapper {
      border-color: var(--color-blue-dark);
    }
  }
}

.lg {
  width: 24px;
  height: 24px;

  .iconWrapper {
    width: 20px;
    height: 20px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

.sm {
  width: 20px;
  height: 20px;

  .iconWrapper {
    width: 16px;
    height: 16px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.checkboxWithLabel {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.checkboxDisabled {
  opacity: 0.32;
  pointer-events: none;
}

.checkboxReadOnly {
  pointer-events: none;
  cursor: default;
}

.field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:checked + .iconWrapper {
    background-color: var(--color-blue-primary);
    border: 2px solid var(--color-blue-primary);

    .icon {
      color: var(--color-white-primary);
      opacity: 1;
    }
  }
}

.iconWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 2px solid #889ab1;
  border-radius: 4px;
  transition: all 0.15s ease-out;
}

.icon {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.label {
  flex: 1;
  padding-left: 8px;
}
