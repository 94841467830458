.item {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  cursor: pointer;
}

.lessonStructure {
  background-color: white;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  margin-top: 4px;
}

.readOnlyItem {
  margin-left: 72px;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(17, 130, 246, 0.1);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
  color: #5c738c;
}

.title {
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;
  color: #021930;
}

.freeTag {
  margin-right: 8px;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.warningIconWrapper {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.warningIcon {
  height: 16px;
  width: 16px;
  color: #fcae2f;
}

.action {
  margin-left: 8px;
}
