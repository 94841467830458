.card {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  display: flex;
  padding: 12px;
  border-radius: 12px;
}

.cardInfo {
  border: 1px solid rgba(17, 130, 246, 0.15);
  background-color: #e7f2fe;

  .icon {
    color: #1182f6;
  }
}

.cardWarning {
  border: 1px solid rgba(252, 174, 47, 0.15);
  background-color: #fff7ea;

  .icon {
    color: #fcae2f;
  }
}

.inner {
  margin-left: 8px;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.title {
  color: #021930;
}

.text {
  margin-top: 4px;
  color: #5c738c;
}

.button {
  position: absolute;
  top: 4px;
  right: 4px;
}
