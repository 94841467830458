.datepicker {
  margin-top: 26px;
  margin-right: -12px;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--color-grey-dark);
  cursor: pointer;
  transition: color 0.15s ease-out;

  &:hover {
    color: var(--color-blue-primary);
  }
}

.iconActive {
  color: var(--color-blue-primary);
}
