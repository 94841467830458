.task {
  margin-top: 8px;
  padding: 12px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #dfe9f9;
  transition: all 0.15s ease-out;

  &:first-child {
    margin-top: 0;
  }
}

.taskCurrent {
  border-color: #1182f6;

  .text {
    color: #021930;
  }
}

.taskCompleted {
  background-color: rgba(52, 203, 115, 0.1);
  border-color: rgba(52, 203, 115, 0.1);

  .header {
    padding: 0;
  }

  .text {
    color: #5c738c;
  }
}

.taskError {
  border-color: #f53149;

  .text {
    color: #021930;
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
}

.headerInner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.name {
  color: #5c738c;
  margin-right: 4px;
}

.hintsIcon {
  margin-right: 4px;
}

.hintsIcon,
.theoryIcon {
  width: 20px;
  height: 20px;
  color: #5c738c;
  cursor: pointer;
  transition: color 0.15s ease-out;

  &:hover {
    color: #1182f6;
  }
}

.readOnlyHintsIcon,
.readOnlyTheoryIcon {
  cursor: default;
  &:hover {
    color:  #5c738c;
  } 
}

.completed {
  width: 28px;
  height: 28px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(52, 203, 115, 0.15);
  border-radius: 50%;
}

.completedIcon {
  color: #34cb73;
}

.inner {
  margin-top: 8px;
}

.text {
  color: #5c738c;
  transition: all 0.15s ease-out;
}

.copy {
  margin-top: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  &:hover {
    .copyIcon,
    .copyText {
      color: #0e68c5;
    }
  }
}

.copyIcon {
  width: 18px;
  height: 18px;
  color: #1182f6;
  transition: color 0.15s ease-out;
}

.copyText {
  margin-left: 2px;
  color: #1182f6;
  transition: color 0.15s ease-out;
}

.errors {
  margin-top: 12px;
}

.error {
  margin-top: 8px;
  position: relative;
  color: #f53149;
  padding-left: 4px;

  &:first-child {
    margin-top: 0;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    border-radius: 16px;
    background-color: #f53149;
  }
}

.materials {
  margin-top: 12px;
}
