.root {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.icon {
  color: #5c738c;
}

.text {
  flex: 1;
  margin-left: 8px;
  color: #5c738c;
}
