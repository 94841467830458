.emptyState {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white-primary);
  border: 1px solid var(--color-grey-medium);
  border-radius: 16px;
}

.icon {
  height: 56px;
  width: 56px;
  color: #5c738c;
}

.text {
  margin-top: 8px;
  color: #5c738c;
  max-width: 328px;
  text-align: center;
}

.title {
  max-width: 328px;
  margin-top: 16px;
  text-align: center;
}
