.modules {
  margin-top: 40px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.inner {
  margin-top: 12px;
}