.material {
  margin-top: 4px;
  padding: 4px 4px 4px 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dfe9f9;
  border-radius: 8px;

  &:first-child {
    margin-top: 0;
  }
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
  color: #5c738c;
}

.text {
  margin-left: 8px;
}
