.button {
  margin-top: 24px;
  margin-left: 6px;
}

.section {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid #dfe9f9;
  border-radius: 8px;
  cursor: pointer;
  margin: 24px 16px 0 16px;

  &:first-child {
    margin-top: 0;
  }

  &:not(.sectionSelected):hover {
    background-color: rgba(17, 130, 246, 0.05);

    .removeBtn {
      opacity: 1;
    }
  }
}

.sectionSelected {
  background-color: rgba(17, 130, 246, 0.1);
  border-color: rgba(17, 130, 246, 0.1);

  .name {
    color: #021930;
  }
}

.inner {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.name {
  color: #5c738c;
}

.removeBtn {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  transition: all 0.15s ease-out;
}
