.tasks {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 16px;
  background-color: #fff;
}

.title {
  color: #021930;
}

.inner {
  margin-top: 16px;
}
