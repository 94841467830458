.section {
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 16px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.headerText {
  margin-left: 16px;
}

.text {
  margin-top: 16px;
  color: #5c738c;
}

.files {
  margin-top: 8px;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 12px;
}

.file {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  column-gap: 16px;
}
