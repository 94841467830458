.info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.text {
  flex: 1;
  margin-left: 4px;
  color: var(--color-grey-dark);
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 6px;
}

.itemWithBorder {
  border-left: 1px solid var(--color-grey-medium);

  &:first-child {
    padding-left: 0;
    padding-right: 6px;
    border-left: 0;
  }
}

.icon {
  width: 18px;
  height: 18px;
  color: var(--color-grey-dark);
}

.difficultyIcon {
  width: 18px;
  height: 18px;
}

