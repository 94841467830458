.modules {
  display: flex;
  flex-flow: column nowrap;
}

.module {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 16px;
  padding: 0 85px 0 15px;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    .moduleMenu {
      opacity: 1;
    }
  }
}

.moduleMenu {
  opacity: 0;
  margin-right: 8px;
}
