.select {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.title {
  margin-bottom: 24px;
  color: #5c738c;
}
