.questions {
  margin-top: 16px;
  display: flex;
  flex-flow: column nowrap;
}

.question {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 16px;
  padding: 0 84px 0 20px;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    .questionMenu {
      opacity: 1;
    }
  }
}

.questionMenu {
  opacity: 0;
  margin-right: 8px;
}

.addBtn {
  margin-left: 90px;
  margin-top: 8px;
}
