.root {
  margin-top: 16px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 8px;
  padding: 16px;
  background-color: rgba(17, 130, 246, 0.05);
}

.rootLarge {
  padding: 16px;

  .title {
    margin-left: 16px;
  }

  .button {
    margin-top: 8px;
    margin-left: -12px;
  }
}

.rootSmall {
  padding: 12px 12px 8px 12px;

  .title {
    margin-left: 12px;
  }

  .button {
    margin-top: 12px;
    width: 100%;
  }

  .title {
    font-size: 14px;
    line-height: 18px;
  }
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.main {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.title {
  color: #021930;
  flex: 1;
}

.info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.infoLink {
  margin-right: 6px;
}

.infoIcon {
  width: 20px;
  height: 20px;
  color: #1182f6;
}
