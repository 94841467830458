.list {
  display: flex;
  flex-flow: column nowrap;
}

.item {
  padding: 12px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid #dfe9f9;
}

.itemDisabled {
  pointer-events: none;
  opacity: 0.32;
}

.itemInner {
  margin-left: 16px;
}
