.card {
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.inner {
  height: 180px;
}

.tooltip {
  padding: 4px 8px;
  background-color: #021930;
  border-radius: 4px;
  width: max-content;
}

.tooltipText {
  color: var(--color-white-primary);
}
