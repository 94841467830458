.page {
  width: 1024px;
  margin: 0px auto;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
}

.inner {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 48px;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dfe9f9;
}

.blocks {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.theoryBlock{
  margin-top: 32px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
