.menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 12px;
  border-top: 1px solid #dfe9f9;
}

.menuCollapsed {
  padding: 8px 16px;
}

.avatar {
  cursor: pointer;
}

.inner {
  margin-left: 8px;
  flex: 1;
  cursor: pointer;
}

.text {
  color: #1c1c1c;
}

.icon {
  width: 24px;
  height: 24px;
  color: #889ab1;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    color: var(--color-blue-primary);
  }
}
