.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.itemOrder {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(98, 60, 234, 0.1);
}

.itemOrderText {
  color: #623cea;
}

.itemText {
  margin-left: 8px;
  flex: 1;
  color: #021930;
}
