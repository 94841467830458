.page {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #f2f6fd;
}

.logo {
  width: 171px;
  height: 48px;
}

.inner {
  display: flex;
  width: 401px;
  padding: 32px;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--color-grey-medium);
  background: var(--color-white-primary);
}

.form {
  width: 100%;
  margin-top: 32px;
}

.agreementText {
  color: #5c738c;
  text-align: center;
  margin-top: 18px;
}

.agreementLink {
  display: inline;
  font-size: 12px;
}

.icons {
  column-gap: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.iconWrapper {
  width: 36px;
  height: 36px;
}

.icon {
  width: 20px;
  height: 20px;
}

.helpText {
  color: #5c738c;
}

.helpLink {
  font-size: 12px;
  line-height: 18px;
}
