.item {
  position: relative;
  height: 280px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.image {
  width: 348px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe9f9;
  border-radius: 16px;
  background-color: #fff;

  > svg {
    max-width: 100%;
  }
}
