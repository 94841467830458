.wrapper {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--color-green-10);
}

.icon {
  height: 20px;
  width: 20px;
  color: var(--color-green-primary);
}
