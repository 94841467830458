.root {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
}

.title {
  font-weight: 400;
  color: #5C738C;

}

.inner {
  position: relative;
  margin-top: 8px;
  height: 300px;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s ease-out;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(2, 25, 48, 0.6);
    transition: opacity .15s ease-out;
    z-index: 1;
  }

  &:hover {
    &:after {
      opacity: 1;
    }

    .actions {
      opacity: 1;
    }
  }
}

.img {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border: 4px solid #dfe9f9;
  border-radius: 4px;
}

.actions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.15s ease-out;
  z-index: 2;
}

.readOnlyButton {
  cursor: default;

  &:hover {
    background-color: var(--color-blue-primary);
  }

  &:active {
    background-color: var(--color-blue-primary);
  }
}

.lessonResult {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 23px 16px;
}

.icon {
color:#5C738C;
width: 18px;
height: 18px;
}