.bar {
  width: 100%;
}

.default {
  height: 6px;

  .thumb {
    background-color: var(--color-blue-15);
    border-radius: 4px;
  }

  .track {
    background-color: var(--color-blue-primary);
    border-radius: 4px;
  }
}

.upload {
  height: 8px;

  .thumb {
    background: rgba(17, 130, 246, 0.1);
    border-radius: 24px;
  }

  .track {
    background-color: #1182f6;
    border-radius: 24px;
  }
}

.thumb {
  height: 100%;
}

.track {
  min-width: 10px;
  height: 100%;
  transition: all 0.15s ease-out;
}
