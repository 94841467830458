.inner {
    padding-top: 16px;
    background-color: var(--color-white-primary);
    border: 1px solid #dfe9f9;
    border-radius: 16px;
    overflow: hidden;
  }
  
  .emptyState {
    margin-top: 16px;
    min-height: calc(100vh - 110px);
  }
  