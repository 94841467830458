.header {
  padding: 14px 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.label {
  margin-left: 8px;
  color: #5c738c;
}

.text {
  margin-left: 2px;
  color: #021930;
}

.triggerIcon {
  width: 20px;
  height: 20px;
  color: #889ab1;
  transition: transform 0.15s ease-out;
}
