.menu {
  margin: 0 12px;
}

.item {
  margin-top: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  transition: background-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
  }
}

.itemActive {
  background-color: rgba(17, 130, 246, 0.1);

  .icon,
  .text {
    color: #021930;
  }
}

.icon {
  width: 24px;
  height: 24px;
  color: #5c738c;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.text {
  flex: 1;
  margin-left: 12px;
  color: #5c738c;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}
