.icon {
  width: 24px;
  height: 24px;
  color: var(--color-grey-dark);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    color: var(--color-blue-primary);
  }
}
