.card {
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.inner {
  margin-top: 30px;
  display: flex;
  flex-flow: row nowrap;
}

.item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 1;
  margin-left: 48px;
  height: 120px;

  &:first-child {
    margin-left: 0;
  }
}

.itemText {
  margin-top: 16px;
}
