.block {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.readOnlyBlock {
  margin-left: 72px;
}
