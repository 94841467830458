.menu {
  width: 275px;
  padding: 8px;
  background-color: var(--color-white-primary);
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-1);
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: #f3f8ff;
  }
}

.itemIcon {
  width: 20px;
  height: 20px;
}

.itemText {
  flex: 1;
  margin-left: 8px;
}
