.pointWrapper {
  position: absolute;
  top: 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);

  &.pointWrapperQuestion {
    right: -12px;
  }

  &.pointWrapperAnswer {
    left: -12px;
  }
}

.point {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: var(--color-white-primary);
  border: 2px solid var(--color-grey-medium);
  border-radius: 50%;
}

.pointCurrent {
  width: 20px;
  height: 20px;
  background-color: var(--color-blue-primary);
  border-color: var(--color-blue-primary);

  .dot {
    background-color: var(--color-white-primary);
  }
}

.pointSuccess {
  width: 20px;
  height: 20px;
  background-color: var(--color-green-primary);
  border-color: var(--color-green-primary);
}

.pointError {
  width: 20px;
  height: 20px;
  background-color: var(--color-red-primary);
  border-color: var(--color-red-primary);
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--color-blue-primary);
  border-radius: 50%;
}
