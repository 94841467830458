.cell {
  display: flex;
  flex: 1 0;
  flex-flow: row nowrap;
  align-items: center;
  word-break: break-word;
}

.text {
  color: var(--color-black-primary);
}
