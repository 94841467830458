.form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  flex: 1;
  margin: 24px;
}

.errorText {
  color: var(--color-red-primary);
  margin-top: 4px;
}

.actions {
  margin-top: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.action {
  flex: 1;
  margin-top: 24px;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
}

.courseItem {
  &:last-child {
    border: none;
  }
}