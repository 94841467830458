.item {
  width: 360px;
  display: flex;
  flex-flow: column nowrap;

  &:first-child {
    margin-left: 0;
  }
}

.image {
  width: 100%;
  height: 210px;
}

.correct {
  margin-top: 12px;
  align-self: flex-end;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.correctText {
  margin-right: 8px;
  color: #5c738c;
}

.text {
  margin-top: 16px;
}
