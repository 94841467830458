.toolbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: #f2f6fd;
  padding: 11px 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.15s ease-out;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 12px;
}

.title {
  color: #021930;
}

.textWrapper {
  min-width: 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding: 2px 4px;
  background-color: var(--color-white-primary);
  border-radius: 4px;
}

.text {
  color: #5c738c;
}

.dropdownIcon {
  width: 20px;
  height: 20px;
  color: #5c738c;
  margin-left: 8px;
  transition: all 0.15s ease-out;
}

.dropdownIconOpen {
  transform: rotate(180deg);
}
