.container {
  --transition-speed: 0.3s;
}

.container.in.open .left,
.container.in.open .right {
  transform: translateX(0);
}

.container.in.open .top,
.container.in.open .bottom {
  transform: translateY(0);
}

.drawer {
  background-color: var(--color-white-primary);
  display: flex;
  flex-flow: column nowrap;
  width: 400px;
  height: 100%;
  overflow: auto;
  position: fixed;
  transition: transform var(--transition-speed) ease;
  z-index: 100;
}

.drawer.left {
  top: 0;
  left: 0;
  transform: translateX(-105%);
}

.drawer.right {
  border-left: 1px solid #dfe9f9;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.drawer.top {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
  height: 40%;
}

.drawer.bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
  height: 40%;
}

.header {
  padding: 16px 16px 0 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

.headerWithContent {
  justify-content: space-between;
}

.title {
  color: #021930;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background-color: rgba(2, 25, 48, 0.1);
  transition: opacity var(--transition-speed) ease,
    visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 2;
}

.container.in.open .backdrop {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 99;
}
