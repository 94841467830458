.preview {
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.img {
  object-fit: contain;
  max-height: 100%;
}
