.count {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.text {
  color: #1182f6;
  margin-right: 4px;
}

.icon {
  width: 20px;
  height: 20px;
  color: #1182f6;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}
