.lesson {
  margin-top: 4px;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:not(.lessonCurrent):hover {
    background-color: rgba(17, 130, 246, 0.05);
  }
}

.lessonCurrent {
  background-color: rgba(17, 130, 246, 0.05);

  .icon {
    color: #0e68c5;
  }
}

.icon {
  width: 16px;
  height: 16px;
  color: #5c738c;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.title {
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  color: #021930;
}

.freeTag {
  margin-left: 8px;
}

.statusIconTrigger {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.statusIcon {
  width: 16px;
  height: 16px;
}

.draftStatusIcon {
  color: #5c738c;
}

.archivedStatusIcon {
  color: #623cea;
}

.publishedStatusIcon {
  color: #34cb73;
}

.warningIconWrapper {
  width: 16px;
  height: 16px;
}

.warningIcon {
  width: 16px;
  height: 16px;
  color: #fcae2f;
}
