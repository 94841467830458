.settings {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 16px;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
  padding: 24px;
}

.field {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.fileUrlControl {
  flex: 1;
}

.button {
  height: 40px;
  margin-left: 8px;
}

.inner {
  margin-top: 32px;
}

.pagesLabel {
  margin-bottom: 8px;
  color: #5c738c;
}

.pages {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.page {
  margin-right: 8px;
  margin-bottom: 8px;
}

.frames {
  margin: 4px 0 -24px 0;
  display: flex;
  flex-flow: row wrap;
}

.frame {
  margin: 0 8px 24px 0;
}
