.spoiler {
  background-color: #fff;
  border: 1px solid #dfe9f9;
  border-radius: 8px;
  transition: border-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    border-color: var(--color-blue-primary);
  }
}

.spoilerOpened {
  .icon {
    transform: rotate(180deg);
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px;
  cursor: pointer;
}

.title {
  margin-left: 12px;
  color: var(--color-black-primary);
}

.inner {
  padding: 0 12px 12px 48px;
}

.text {
  color: var(--color-black-primary);
}

.icon {
  color: #5c738c;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  cursor: pointer;
}
