.rating {
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ratingResponsive {

  .stars {

    .star {
      width: 20px;
      height: 20px;
    }
  }

  .emptyStars {

    .emptyStar {
      width: 20px;
      height: 20px;
    }
  }
}

.ratingReadonly {

  .star,
  .emptyStar {
    cursor: default;
  }
}

.ratingQualityValue {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  width: 32px;
  height: 24px;
  color: var(--color-grey-dark);
}

.stars {
  z-index: 2;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
}

.starsWithQuality {
  position: absolute;
  left: 35px;
  display: flex;
  flex-flow: row nowrap;
}

.star {
  overflow: initial;
  color: var( --color-yellow-primary);
  cursor: pointer;
  transition: all .15s ease-out;
}

.emptyStars {
  position: absolute;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
}

.emptyStarsWithQuality {
  left: 35px;
}

.emptyStar {
  color: #5C738C;
  cursor: pointer;
  cursor: pointer;
  transition: all .15s ease-out;
}

@media all and (min-width: 768px) {

  .ratingResponsive {

    .stars {
  
      .star {
        width: 24px;
        height: 24px;
      }
    }
  
    .emptyStars {
  
      .emptyStar {
        width: 24px;
        height: 24px;
      }
    }
  }
  
}
