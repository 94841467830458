.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: rgba(2, 25, 48, 0.6);
}

.overlayCentered {
  justify-content: center;
}

.modal {
  margin: 40px auto;
  padding: 24px;
  background-color: var(--color-white-primary);
  box-shadow: var(--shadow-elevation-2);
  border-radius: 16px;
  outline: none;
}

.icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  width: 24px;
  height: 24px;
  color: #889ab1;
  cursor: pointer;
  transition: color-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    color: var(--color-blue-primary);
  }
}

:global {
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    transform: scale(0);
    transition: all 0.3s ease-in-out;
  }

  .ReactModal__Content--after-open {
    transform: scale(1);
  }

  .ReactModal__Content--before-close {
    transform: scale(0);
  }
}
