.card {
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
}

.main {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.inner {
  margin-left: 16px;
}

.preview {
  width: 56px;
  height: 56px;
}

.tag {
  margin-top: 4px;
}
