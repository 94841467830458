.page {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.inner {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.title {
  color: #5c738c;
  margin-bottom: 8px;
}

.main {
  margin: -8px;
}

.swiper {
  width: 100%;
  padding: 8px !important;
}

.question {
  display: flex;
  flex-flow: column nowrap;
}

.answers {
  margin-top: 16px;
}

.emptyAnswers {
  width: 720px;
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  gap: 12px;
  background: linear-gradient(90deg, #E1EAFC 7.81%, rgba(225, 235, 252, 0.37) 100%);
}

.actions {
  display: flex;
  justify-content: flex-end;
}