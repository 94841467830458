.answer {
  margin-top: 16px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.preview {
  width: 402px;
  height: 402px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 16px;
  cursor: pointer;
}

.pluginPreview {
  max-width: 348px;
  height: 348px;
}

.previewCorrect {
  border: 2px solid #34cb73;
}

.previewIncorrect {
  border: 2px solid #f53149;
}

.previewText {
  margin-top: 8px;
  color: var(--color-grey-dark);
}

.previewImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.iconWrapper {
  position: absolute;
  bottom: -8px;
  right: -8px;
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon {
  width: 24px;
  height: 24px;
}

.title {
  margin-left: 12px;
}

.comment {
  position: relative;
  margin-top: 24px;
  background-color: #fff;
  border: 1px solid #dfe9f9;
  padding: 12px 12px 12px 16px;
  border-radius: 8px;
}

.commentCorrect {
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #34cb73;
  }
}

.commentIncorrect {
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #f53149;
  }
}

.commentText {
  color: #021930;
}
