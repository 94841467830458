.page {
  margin-top: 16px;
}

.inner {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 16px;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
  padding: 24px 96px 24px 24px;
}
