.avatar {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
}

.lg {
  width: 72px;
  height: 72px;

  .text {
    font-size: 22px;
    line-height: 28px;
  }
}

.md {
  width: 48px;
  height: 48px;

  .text {
    font-size: 18px;
    line-height: 24px;
  }
}

.sm {
  width: 36px;
  height: 36px;

  .text {
    font-size: 11px;
    line-height: 20px;
  }
}

.preview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.text {
  color: var(--color-white-primary);
  text-transform: uppercase;
}
