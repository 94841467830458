.tab {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 18px 0 16px 0;
  margin-left: 24px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
}

.tabActive {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #1182f6;
  }

  .icon {
    color: #021930;
  }

  .text {
    color: #021930;
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: #5c738c;
}

.text {
  color: #5c738c;
}

.count {
  margin-left: 8px;
  color: #5c738c;
}
