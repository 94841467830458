.sidebar {
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 224px;
  height: 100%;
  border-right: 1px solid #dfe9f9;
  background-color: var(--color-white-primary);
  z-index: 1;
}

.sidebarCollapsed {
  width: 76px;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
}

.logo {
  display: block;
  width: 114px;
  height: 32px;
  margin: 32px 24px 24px 24px;
}

.logoImg {
  width: 114px;
  height: 32px;
}

.logoCollapsed {
  width: 26px;
  height: 32px;

  .logoImg {
    width: 26px;
    height: 32px;
  }
}

.collapse {
  position: absolute;
  top: 36px;
  right: -12px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.collapseIcon {
  width: 20px;
  height: 20px;
  color: #889ab1;
}

.menuItem {
  margin: 4px 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  transition: background-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
  }
}

.menuItemActive {
  background-color: rgba(17, 130, 246, 0.1);

  .menuItemIcon,
  .menuItemText {
    color: #021930;
  }
}

.menuItemIcon {
  width: 24px;
  height: 24px;
  color: #5c738c;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.menuItemText {
  flex: 1;
  margin-left: 12px;
  color: #5c738c;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}
