.addMenu {
  width: 275px;
}

.addBtn {
  margin-left: 60px;
}

.addBtnActive {
  background-color: var(--color-blue-10);
}

.blocks {
  margin-bottom: 16px;
}

.block {
  flex: 1;
  position: relative;
  margin-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    .blockMenu {
      opacity: 1;
    }
  }
}

.blockMenu {
  margin-right: 8px;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}
