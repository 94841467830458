.item {
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 16px;
}

.emptyState {
  padding: 40px;
}
