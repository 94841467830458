.layout {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  background-color: #f2f6fd;
  overflow: hidden;
}

.container {
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  overflow: auto;
}

.inner {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

.main {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  padding: 24px;
}

@media all and (min-width: 1440px) {
  .main {
    padding: 24px 32px;
  }
}

@media all and (min-width: 1920px) {
  .main {
    padding: 24px 40px;
  }
}
