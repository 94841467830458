.title {
  margin: 0 96px 8px 96px;
  color: #5c738c;
}

.answers {
  margin-top: 16px;
}

.answer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 16px;
  padding: 0 24px;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    .answerMenu {
      opacity: 1;
    }
  }
}

.answerMenu {
  opacity: 0;
  margin-right: 8px;
}

.addBtn {
  margin-top: 16px;
  margin-left: 94px;
}
