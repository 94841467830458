.lessons {
  display: flex;
  flex-flow: column nowrap;
}

.lesson {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &:hover {
    .lessonMenu {
      opacity: 1;
    }
  }
}

.lessonMenu {
  opacity: 0;
  margin-top: 8px;
  margin-right: 8px;
}
