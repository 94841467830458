.radio {
  position: relative;
  cursor: pointer;

  &:not(.radioDisabled):not(.radioReadOnly):hover {
    .iconWrapper {
      border-color: var(--color-blue-primary);
    }
  }

  &:not(.radioDisabled):not(.radioReadOnly):active {
    .iconWrapper {
      border-color: var(--color-blue-dark);
    }
  }
}

.radioDisabled {
  opacity: 0.32;
  pointer-events: none;
}

.radioWithLabel {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.radioReadOnly {
  pointer-events: none;
  cursor: default;
}

.field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:checked + .iconWrapper {
    border: 2px solid var(--color-blue-primary);

    .icon {
      opacity: 1;
    }
  }
}

.iconWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 2px solid #889ab1;
  border-radius: 50%;
  transition: all 0.15s ease-out;
}

.icon {
  width: 12px;
  height: 12px;
  background-color: var(--color-blue-primary);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.15s ease-out;
}

.label {
  flex: 1;
  margin-left: 12px;
}
