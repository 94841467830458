.header {
    display: flex;
    flex-flow: row nowrap;
    padding: 12px 16px;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grey-medium);
  }
  
  .courseCell {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: 16px;
    width: 300px;
  }
  
  .coursePreview {
    width: 40px;
    height: 40px;
  }
  
  .courseDescription {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 8px;
    gap: 4px;
  }
  
  .courseTitle {
    flex: 1;
  }
  
  .lessonTitle {
    color:#5C738C;
  }

  .date {
    color: #5C738C;
    width: 150px;
    display: flex;
    justify-content: flex-end;
  }
  
  .content {
    padding: 16px;
  }
  
  .tags {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
  }
  
  .tag {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-grey-medium);
    background: var(--color-blue-5);
    padding: 5px 8px;
    border-radius: 24px;
  }
  
  .tagText {
    color: #021930;
  }

  .text {
    margin-top: 16px;
  }
