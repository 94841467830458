.info {
  flex: 1;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  border-radius: 16px;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.main {
  margin-left: 16px;
}

.nameWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.tags {
  margin-left: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.tag {
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.name {
  color: #021930;
}

.emailWrapper {
  margin-top: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.email {
  color: #5c738c;
}

.copyIcon {
  margin-left: 4px;
  width: 16px;
  height: 16px;
  color: #1182f6;
  cursor: pointer;
}

.status {
  margin-top: 8px;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 32px;
}

.activityInfo {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin-right: 24px;
}

.dateLabel {
  color: #5c738c;
}

.dateText {
  color: #021930;
}
