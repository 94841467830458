.page {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.inner {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.text {
  color: #021930;
  margin-bottom: 16px;
}

.main {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 24px;
}

.items {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  column-gap: 24px;
}

.image {
  width: 348px;
  height: 280px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  border-radius: 16px;

  > svg {
    max-width: 100%;
  }
}

.picker {
  flex: 1;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}