.properties {
  display: flex;
  flex-flow: column nowrap;
}

.propertiesRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }
}
