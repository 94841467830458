.header {
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 13px 24px;
  background-color: var(--color-white-primary);
  border-bottom: 1px solid var(--color-grey-medium);
  z-index: 100;
}

.logo {
  width: 114px;
  height: 32px;
  vertical-align: top;
}
