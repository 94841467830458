.select {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.md {
  .option {
    width: 126px;
    height: 126px;
  }

  .optionIcon {
    width: 24px;
    height: 24px;
  }

  .optionText {
    font-size: 12px;
    line-height: 18px;
  }
}

.lg {
  .option {
    width: 158px;
    height: 158px;
  }

  .optionIcon {
    width: 34px;
    height: 34px;
  }
}

.option {
  margin-left: 12px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid #dfe9f9;
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
    border-color: rgba(17, 130, 246, 0.1);
  }
}

.optionIcon {
  color: #1182f6;
}

.optionText {
  margin-top: 8px;
  color: #1182f6;
}
