.breadcrumbs {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.items {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.link {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #5c738c;

  &:hover {
    color: #5c738c;
  }

  &:active {
    color: #5c738c;
  }
}

.text {
  color: #021930;
}

.icon {
  margin: 0 4px;
  width: 18px;
  height: 18px;
  color: #5c738c;
}
