.caption {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.prevMonthBtn {
  margin-left: 4px;
}

.text {
  flex: 1;
  text-align: center;
}

.nextMonthBtn {
  margin-right: 4px;
}
