.header {
  display: flex;
  flex-flow: row nowrap;
  padding: 12px 16px;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey-medium);
}

.emailFromContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 300px;
}

.emailFrom {
  color: #5C738C;
}

.date {
  color: #5C738C;
  width: 150px;
  display: flex;
  justify-content: flex-end;
}

.content {
  padding: 16px;
}

.tags {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
}

.tag {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-grey-medium);
  background: var(--color-blue-5);
  padding: 5px 8px;
  border-radius: 24px;
}

.tagText {
  color: #021930;
}