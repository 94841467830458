.titleCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.authorsCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.authors {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 12px;
}

.authorTrigger {
  margin-left: -12px;
  position: relative;

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    position: absolute;
    content: '';
    border: 2px solid #ffffff;
    opacity: 0;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    border-radius: 50%;
    transition: opacity 0.15s ease-out;
  }
}

.author {
  // margin-left: -12px;
}

.statusCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  width: 40px;
  height: 40px;
}

.title {
  margin-left: 16px;
}

.subtitle {
  margin: 4px 0 0 16px;
  color: #5c738c;
}

.schoolCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
