.status {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 6px;
  transition: background-color 0.15s ease-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
  }
}

.lg {
  padding: 8px 4px;

  .text {
    font-size: 14px;
    line-height: 18px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .dropdownIcon {
    width: 20px;
    height: 20px;
  }
}

.sm {
  padding: 7px 4px;

  .icon {
    width: 18px;
    height: 18px;
  }

  .dropdownIcon {
    width: 18px;
    height: 18px;
  }
}

.statusActive {
  background-color: rgba(17, 130, 246, 0.15);

  .dropdownIcon {
    transform: rotate(180deg);
  }
}

.statusDraft {
  .text,
  .icon {
    color: #5c738c;
  }
}

.statusPublished {
  .text {
    color: #021930;
  }

  .icon {
    color: #34cb73;
  }
}

.statusArchived {
  .text {
    color: #021930;
  }

  .icon {
    color: #623cea;
  }
}

.text {
  margin: 0 4px;
}

.dropdownIcon {
  color: #889ab1;
  transition: transform 0.15s ease-out;
}

.menu {
  margin-top: 4px;
}
