.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.title {
  flex: 1;
  margin-right: 24px;
}

.free {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.freeLabel {
  margin-right: 16px;
  color: #5c738c;
}
