.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  background-color: rgba(17, 130, 246, 0.1);
  border: 1px solid 1px solid rgba(17, 130, 246, 0.15);
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}

.icon {
  color: #1182f6;
}

.main {
  flex: 1;
  margin-left: 8px;
}

.title {
  color: #1182f6;
}

.text {
  margin-top: 8px;
  color: #5c738c;
}

.progress {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: rgba(17, 130, 246, 0.1);
  border: 1px solid 1px solid rgba(17, 130, 246, 0.15);
  border-radius: 12px;
  height: 78px;
}

.progressTrack {
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: rgba(17, 130, 246, 0.15);
  height: 78px;
}
