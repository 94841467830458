.page {
  width: 818px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
}

.answers {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 16px;
}

.answer {
  flex: 1;
  max-width: 402px;
  height: 402px;
  margin-left: 14px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: 1px solid #dfe9f9;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  transition: border-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border: 2px solid var(--color-blue-primary);
    padding: 5px;
  }
}

.readOnlyAnswer {
  cursor: default;
  &:hover {
    border: 1px solid #dfe9f9;
    background-color: var(--color-white-primary);
    padding: 6px;
  }
}

.answerImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}