.trigger {
  width: max-content;
}

.popover {
  z-index: 100;
}

.popoverBottomStart {
  margin-top: 8px;
}

.popoverRightEnd {
  margin-left: 8px;
}

.popoverLeftEnd {
  margin-right: 8px;
}
