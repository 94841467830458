.typography {
}

.inter {
  font-family: 'Inter', sans-serif;
}

.prompt {
  font-family: 'Prompt', sans-serif;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semiBold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.heading1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

.heading2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}

.heading3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.body1 {
  font-size: 16px;
  line-height: 28px;

  &.compact {
    line-height: 20px;
  }
}

.body2 {
  font-size: 14px;
  line-height: 24px;

  &.compact {
    line-height: 18px;
  }
}

.body3 {
  font-size: 12px;
  line-height: 20px;

  &.compact {
    line-height: 14px;
  }
}

.caption {
  font-size: 10px;
  line-height: 16px;

  &.compact {
    line-height: 12px;
  }
}
