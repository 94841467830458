.card {
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.inner {
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
}

.item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-left: 11px;

  &:first-child {
    margin-left: 0;
  }
}

.itemBar {
  display: flex;
  flex-flow: column-reverse nowrap;
  width: 20px;
  height: 142px;
  border-radius: 2px;
}

.itemBarTrack {
  border-radius: 2px;
}

.itemText {
  margin-top: 4px;
  color: #5c738c;
}

.itemTooltip {
  color: var(--color-white-primary);
}
