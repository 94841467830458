.property {
  width: max-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 36px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  padding: 2px 4px;
}

.item {
  width: 20px;
  height: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.itemActive {
  background-color: rgba(17, 130, 246, 0.1);
}

.itemIcon {
  width: 16px;
  height: 16px;
  color: #5c738c;
}
