.hue {
  margin-top: 8px;
  position: relative;
  height: 20px;
  border-radius: 48px;

  :global(.hue-horizontal) {
    border-radius: 48px;
  }
}

.saturation {
  position: relative;
  height: 252px;
  border-radius: 16px;

  > div {
    border-radius: 16px;
  }

  :global(.saturation-white),
  :global(.saturation-black) {
    border-radius: 16px;
  }
}

.huePointer {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid var(--color-white-primary);
  box-shadow: var(--shadow-elevation-2);
  cursor: pointer;
  transform: translateX(-10px);
}

.saturationPointer {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid var(--color-white-primary);
  box-shadow: var(--shadow-elevation-1);
  cursor: pointer;
  transform: translate(-12px, -12px);
}
