.block {
  width: 100%;
}

.readOnlyBlock {
  margin-left: 72px;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.type {
  width: 100px;
}

.value {
  flex: 1;
  margin-left: 8px;
}
