.icons {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 8px;
}

.iconWrapper {
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: rgba(#1182f6, 0.05);
  border-radius: 50%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: var(--color-blue-primary);

    .socialIcon {
      color: var(--color-white-primary);
    }
  }

  &:active {
    background-color: #0a4e94;

    .socialIcon {
      color: var(--color-white-primary);
    }
  }
}

.socialIcon {
  width: 18px;
  height: 18px;
  color: #5c738c;
  transition: color 0.15s ease-out;
}
