.inner {
  padding-top: 16px;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  border-radius: 16px;
  overflow: hidden;
}

.nameCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  width: 40px;
  height: 40px;
}

.name {
  margin-left: 16px;
}

.statusCell {
  flex: 1 0;
}

.coursesCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.courseTrigger {
  width: 32px;
  height: 32px;
  margin-left: 2px;
}

.coursePreview {
  width: 32px;
  height: 32px;
}

.coursesMoreBtn {
  margin-left: 2px;
}

.courseTooltip {
  color: var(--color-white-primary);
}

.emptyState {
  margin-top: 16px;
  min-height: calc(100vh - 110px);
}
