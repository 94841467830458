.link {
  color: var(--color-blue-primary);
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    color: var(--color-blue-medium);
  }

  &:active {
    color: var(--color-blue-dark);
  }
}
