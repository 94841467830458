.answer {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 4px;
  padding: 15px 17px;
  background-color: var(--color-white-primary);
  border-radius: 8px;
  border: 1px solid #dfe9f9;
  cursor: pointer;
  transition: background-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: rgba(17, 130, 246, 0.05);
  }
}

.pluginAnswer {
  padding: 13px 17px;
}

.readOnlyAnswer {
  cursor: default;
  &:hover {
    background-color: var(--color-white-primary);
  }
}

.answerSelected {
  background-color: rgba(17, 130, 246, 0.1);
}

.answerCorrect {
  .iconWrapperCorrect {
    opacity: 1;
  }
}

.answerIncorrect {
  .iconWrapperIncorrect {
    opacity: 1;
  }
}

.answerSubmitted {
  pointer-events: none;
}

.answerSubmittedSelected {
  background-color: #f7f9fd;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1;
}

.text {
  flex: 1;
  margin-left: 12px;
  color: #021930;
}

.iconWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  opacity: 0;
  width: 28px;
  height: 28px;
  transition: opacity 0.15s ease-out;
}

.iconWrapperCorrect {
  background-color: rgba(52, 203, 115, 0.15);
}

.iconWrapperIncorrect {
  background-color: rgba(245, 49, 73, 0.15);
}

.iconWrapperComment {
  top: 12px;
  transform: translateY(0);
}

.iconCorrect {
  color: #34cb73;
}

.iconIncorrect {
  color: #f53149;
}

.comment {
  position: relative;
  margin: 16px 0 0 36px;
  padding-left: 12px;
  color: rgba(#021930, 0.8);
}

.commentCorrect {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 8px;
    background-color: #34cb73;
  }
}

.commentIncorrect {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 8px;
    background-color: #f53149;
  }
}

