.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.inner {
  margin-left: 16px;
  flex: 1;
}

.tag {
  margin-left: -4px;
}
