.frame {
  width: 212px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.preview {
  position: relative;
  width: 100%;
  height: 212px;
  border-radius: 8px;
}

.previewValid {
  border: 2px solid #34cb73;

  .iconWrapper {
    background-color: #34cb73;
  }
}

.previewInvalid {
  border: 2px solid #f53149;

  .iconWrapper {
    background-color: #f53149;
  }
}

.iconWrapper {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--color-white-primary);
}

.previewImg {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.inner {
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.name {
  color: #5c738c;
}
