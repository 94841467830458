.item {
  width: max-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe9f9;
  border-radius: 8px;
  padding: 12px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color,
    background-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    background-color: #e7f2fe;
  }
}

.itemDragging {
  background-color: #f2f6fd;

  .text {
    color: #5c738c;
  }
}

.text {
  color: #021930;
}
