.settings {
  display: flex;
  flex-flow: column nowrap;
}

.section {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
}

.sectionImage {
  padding-top: 24px;
  border-top: 1px solid #dfe9f9;
}

.sectionTitle {
  color: #5c738c;
  margin-bottom: 8px;
}

.image {
  width: 378px;
  height: 208px;
}
