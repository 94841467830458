.inner {
  margin: 24px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  width: 48px;
  height: 48px;
}

.name {
  flex: 1;
  margin-left: 16px;
}

.courses {
  margin-top: 20px;
  padding-top: 8px;
  border-top: 1px solid #dfe9f9;
  display: flex;
  flex-flow: column nowrap;
}

.course {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 0;
}

.coursePreview {
  width: 40px;
  height: 40px;
}

.courseTitle {
  margin-left: 16px;
}
