.tag {
  max-width: max-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
}

.info {
  border: 1px solid rgba(17, 130, 246, 0.1);
  background-color: rgba(17, 130, 246, 0.1);
}

.default {
  border: 1px solid #dfe9f9;
  background-color: #f2f6fd;

  .text {
    color: #889ab1;
  }
}

.success {
  border: 1px solid rgba(52, 203, 115, 0.1);
  background-color: rgba(52, 203, 115, 0.1);
}

.error {
  border: 1px solid rgba(252, 174, 47, 0.1);
  background-color: rgba(245, 49, 73, 0.1);
}

.text {
  color: #021930;
}
