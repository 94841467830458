.tab {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 7px 12px;
  margin-left: 2px;
  cursor: pointer;
  border-radius: 6px;

  .text {
    color: #5c738c;
  }

  &:first-child {
    margin-left: 0;
  }
}

.tabActive {
  position: relative;
  background-color: #ffff;

  .icon {
    color: #021930;
  }

  .text {
    color: #021930;
  }
}
