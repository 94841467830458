.actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

}

.action {
    color: #5C738C;
}

.form {
    display: flex;
    flex-flow: column nowrap;
    background-color: #FFFFFF;
    width: 278px;
    padding: 16px;
    border-radius: 8px;
    gap: 16px;
    box-shadow: 0px 0px 1px 0px rgba(22, 53, 122, 0.02), 0px 1px 4px 0px rgba(22, 53, 122, 0.06), 0px 1px 22px 0px rgba(22, 53, 122, 0.09);
}

.sortActions {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px
}

.sortAction {
    width: 100%;
    border-radius: 38px;
}

.searchIcon {
margin-right: -6px;
}