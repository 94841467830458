.item {
  flex: 1;
  padding: 24px 24px 16px 24px;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  border-radius: 16px;
}

.readOnlyItem {
  margin-left: 72px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  height: 46px;
  width: 46px;
}

.title {
  flex: 1;
  margin-left: 24px;
}

.inner {
  margin-top: 16px;
}

.addBtn {
  margin-left: 65px;
}
