.modal {
  width: 498px;
}

.bar {
  margin-top: 24px;
  height: 12px;

  .barThumb {
    border-radius: 16px;
  }

  .barTrack {
    border-radius: 16px;
  }
}

.steps {
  margin-top: 24px;
}

.step {
  margin-top: 6px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 44px;

  &:first-child {
    margin-top: 0;
  }
}

.stepIcon {
  color: #889ab1;
}

.stepIconCompleted {
  color: #34cb73;
}

.stepIconError {
  color: #f53149;
}

.stepText {
  flex: 1;
  margin-right: 32px;
  margin-left: 8px;
  color: #021930;
}
