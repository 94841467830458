.block {
  flex: 1;
}

.readOnlyBlock {
  margin-left: 72px;
}

.rulesText {
  margin-top: 8px;
}

.caption {
  margin-top: 12px;
  padding: 4px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.captionLine {
  margin-right: 12px;
  width: 2px;
  height: 24px;
  background-color: #dfe9f9;
}

.errorText {
  color: #f53149;
}
