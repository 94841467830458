 .inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 300px;
    cursor: pointer;
  }
  
  .main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 8px;
  }
  
  .email {
    margin-top: 4px;
    color: #5C738C;
  }
