.rowCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
}

.studentCell {
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
}

.studentName {
  color: #021930;
}

.studentEmail {
  margin-top: 4px;
  color: #5c738c;
}

.results {
  cursor: pointer;
  border-bottom: 1px solid #dfe9f9;
}

.resultsOpened {
  .resultsTriggerIcon {
    transform: rotate(180deg);
  }
}

.resultsTable {
  padding: 0 12px;
}
