.upload {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #f2f6fd;
  border: 1px solid #dfe9f9;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:not(.uploadWithValue):not(.uploadReadOnly):hover {
    background-color: rgba(17, 130, 246, 0.1);
    border-color: #1182f6;

    .icon,
    .text {
      color: #1182f6;
    }
  }

  &:hover {
    .actionOverlay {
      opacity: 1;
    }

    .action {
      opacity: 1;
    }
  }
}

.uploadReadOnly {
  cursor: default;
}

.sm {
  width: 48px;
  height: 48px;
  padding: 0 4px;
}

.md {
  width: 80px;
  height: 80px;
  padding: 0 8px;
}

.uploadWithValue {
  padding: 0;
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.preview {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.icon {
  width: 24px;
  height: 24px;
  color: #5c738c;
  transition: color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.text {
  margin-top: 8px;
  color: #5c738c;
  transition: color0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.actionOverlay {
  opacity: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-black-60);
  border-radius: 8px;
  z-index: 1;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.action {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}
