.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.avatar {
  margin-right: 16px;
}

.dateText {
  margin-top: 4px;
  color: #969696;
}

.loading {
  position: relative;
  min-height: calc(100vh - 100px);
}

.course {
  margin-top: 24px;
}

.module {
  border-bottom: 1px solid #dfe9f9;
}

.moduleOpened {
  .moduleIcon {
    transform: rotate(180deg);
  }
}

.moduleHeader {
  padding: 6px 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}

.modulePreview {
  width: 40px;
  height: 40px;
}

.moduleTitle {
  margin: 0 12px;
  flex: 1;
  color: #021930;
}

.progressText {
  color: #5c738c;
  margin-left: 8px;
  width: 32px;
  text-align: left;
}

.moduleProgress {
  color: #5c738c;
  margin: 0 6px;
}

.moduleIcon {
  width: 20px;
  height: 20px;
  color: #889ab1;
}

.moduleInner {
  padding-left: 69px;
}

.lesson {
  padding: 8px 24px 8px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid #dfe9f9;

  &:last-of-type {
    border-bottom: none;
  }
}

.lessonStructure {
  padding: 8px 24px 8px 24px;
}

.iconWrapper {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--color-blue-10);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.icon {
  width: 16px;
  height: 16px;
  color: #5c738c;
}

.lessonInfo {
  flex: 1;
}

.lessonDateText {
  color: #5c738c;
}
