.settings {
  margin-top: 16px;
  display: flex;
  flex-flow: column nowrap;
  padding: 24px;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
}

.label {
  color: #5c738c;
  margin-bottom: 8px;
  margin-left: 72px;
}
