.outerСircle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--color-violet-primary);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.innerСircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--color-yellow-medium);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.order {
    color: var(--color-white-primary)
}

