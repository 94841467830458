.root {
  display: flex;
  flex-flow: column nowrap;
}

.item {
  margin-top: 12px;
  padding: 12px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 8px;
  border: 1px solid #dfe9f9;

  &:first-child {
    margin-top: 0;
  }
}

.itemTitle {
  color: #5c738c;
}

.itemInner {
  margin-top: 16px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 4px;
  row-gap: 12px;
}
