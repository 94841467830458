.page {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #f2f6fd;
}

.logo {
  width: 171px;
  height: 48px;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  width: 400px;
  margin-top: 40px;
  border-radius: 16px;
  border: 1px solid var(--color-grey-medium);
  background: var(--color-white-primary);
}

.header {
  padding: 9px 22px;
  display: flex;
  flex-flow: column nowrap;
}

.main {
  padding: 8px 32px 32px 32px;
}

.form {
  width: 100%;
  margin-top: 32px;
}

.successIcon {
  width: 108px;
  height: 108px;
  margin: 16px 32px;
}

.title {
  color: #021930;
  margin-bottom: 8px;
}

.text {
  color: #5c738c;
}

.textEmail {
  display: inline;
  color: #021930;
}

.button {
  width: 100%;
  margin-top: 32px;
}

.icons {
  column-gap: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.iconWrapper {
  width: 36px;
  height: 36px;
}

.icon {
  width: 20px;
  height: 20px;
}

.helpText {
  color: #5c738c;
}

.helpLink {
  font-size: 12px;
  line-height: 18px;
}
