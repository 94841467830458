.tabs {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.header {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: 1px solid #dfe9f9;
}

.headerSwitch {
  border-bottom: none;
}

.items {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.itemsMenu {
  width: 100%;
}

.itemsSwitch {
  padding: 2px;
  background: #dfe9f9;
  border-radius: 8px;
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.actionTooltip {
  color: var(--color-white-primary);
}

.inner {
  flex: 1;
  margin-top: 24px;
}

.innerMenu {
  margin-top: 0;
}

.innerProperties {
  margin-top: 0;
}
