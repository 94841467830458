.tag {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--color-violet-15);
  padding: 2px 4px;
  width: max-content;
}

.text {
  line-height: 12px;
  color: var(--color-violet-primary);
}
