.editor {
  width: 100%;
  color: var(--color-black-primary);
  border-radius: 4px;
  border: 1px solid var(--color-grey-medium);
}

.editorWithError {
}

.editorDisabled {
  background-color: var(--color-grey-light);
  opacity: 0.32;
  pointer-events: none;
}

.toolbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: var(--color-white-primary);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0 4px;
}

.marks,
.blocks {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-right: 1px solid var(--color-grey-medium);
}

.input {
  padding: 8px 12px;
  background-color: var(--color-grey-light);
  min-height: 162px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;

  span[data-slate-placeholder='true'] {
    color: var(--color-grey-dark);
    opacity: 1 !important;
    font-weight: 400 !important;
  }
}
