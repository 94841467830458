.module {
}

.moduleOpened {
  .moduleIcon {
    transform: rotate(180deg);
  }
}

.moduleHeader {
  padding: 6px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
  }
}

.modulePreview {
  width: 28px;
  height: 28px;
}

.moduleTitle {
  margin: 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  color: #021930;
}

.moduleIcon {
  width: 20px;
  height: 20px;
  color: #889ab1;
  transition: transform 0.15s ease-out;
}

.moduleInner {
  margin: 0 8px 8px 8px;
}

.addBtn {
  margin-top: 4px;
}
