.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fd;
}

.difference,
.quiz {
  border-radius: 16px;
}

.connectionQuiz {
  border-radius: 8px;
}

.icon {
  width: 64px;
  height: 64px;
  color: #889ab1;
}

.text {
  margin-top: 16px;
  color: #889ab1;
}
