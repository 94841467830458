.input {
  width: 100%;
  position: relative;
}

.inputDisabled {
  background-color: var(--color-grey-light);
  opacity: 0.56;
  pointer-events: none;
}

.field {
  width: 100%;
  padding: 11px;
  color: var(--color-black-primary);
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  line-height: 16px;
  border: 1px solid var(--color-grey-medium);
  background-color: var(--color-grey-light);
  border-radius: 8px;
  outline: none;
  transition: background-color,
    border-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &::placeholder {
    color: var(--color-grey-dark);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    padding: 10px;
    background-color: var(--color-white-primary);
    border: 2px solid var(--color-blue-primary);
  }

  &:disabled {
    background-color: var(--color-grey-light);
    opacity: 0.32;
    pointer-events: none;
  }
}

.fieldHaveValue {
  background-color: var(--color-white-primary);
}

.fieldWithStartAdornment {
  padding-left: 40px;

  &:focus {
    padding-left: 39px;
  }
}

.fieldWithEndAdornment {
  padding-right: 40px;

  &:focus {
    padding-right: 39px;
  }
}

.fieldMultiline {
  min-height: 180px;
  resize: none;
}

.fieldHaveError {
  padding: 10px;
  border: 2px solid var(--color-red-primary);
}

.startAdornment {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.endAdornment {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
