.settings {
  margin-top: 16px;
  display: flex;
  flex-flow: column nowrap;
}

.button {
  margin-left: 85px;
  margin-top: 8px;
}
