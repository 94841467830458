.select {
  position: relative;
  width: max-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 36px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  padding: 4px 4px 4px 8px;
}

.selectChecked {
  border-color: #1182f6;
}

.field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:checked + .iconWrapper {
    border-color: #1182f6;
    background-color: #1182f6;

    .icon {
      opacity: 1;
    }
  }
}

.iconWrapper {
  margin-left: 8px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #f2f6fd;
  border: 1px solid #dfe9f9;
  border-radius: 50%;
}

.icon {
  width: 18px;
  height: 18px;
  color: #fff;
  opacity: 0;
}
