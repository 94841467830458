.page {
  width: 818px;
  margin: 0px auto;
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.main {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 24px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}