.cell {
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  align-items: center;
}

.nameText {
  margin-left: 16px;
}
