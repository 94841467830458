.title {
  margin: 0 96px 8px 96px;
  color: #5c738c;
}

.inner {
  margin: 0 24px;
}

.answers {
  margin-top: 16px;
}

.answer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    .answerMenu {
      opacity: 1;
    }
  }
}

.answerMenu {
  opacity: 0;
  margin-right: 8px;
}

.divider {
  margin: 16px 96px 24px 96px;
  height: 1px;
  background-color: #dfe9f9;
}

.addBtn {
  margin-top: 16px;
  margin-left: 94px;
}
