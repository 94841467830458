.page {
  flex: 1;
  background-color: #f2f6fd;
}

.section {
  margin-top: 32px;
}

.emptyState {
  margin-top: 16px;
  min-height: calc(100vh - 250px);
}
