.container {
  width: 100%;
  height: 40px;
}

.control {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 7px 0 11px;
  background-color: var(--color-white-primary);
  border: 1px solid var(--color-grey-medium);
  border-radius: 8px;
  box-shadow: none;
  cursor: pointer;
  transition: border-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  .indicator {
    width: 24px;
    height: 24px;
    color: var(--color-grey-dark);
    transition: all 0.15s ease-out;
  }

  &:hover {
    padding: 0 6px 0 10px;
    background-color: var(--color-white-primary);
    border: 2px solid var(--color-blue-primary);

    .tag {
      margin: 2px 4px 2px 0;
    }
  }
}

.controlHaveError {
  padding: 0 6px 0 10px;
  border: 2px solid var(--color-red-primary);

  .tag {
    margin: 2px 4px 2px 0;
  }
}

.controlOpen {
  background-color: var(--color-white-primary);
  border: 2px solid var(--color-blue-primary);

  .tag {
    margin: 2px 4px 2px 0;
  }

  .indicator {
    transform: rotate(180deg);
  }
}

.controlFocused {
  padding: 0 6px 0 10px;
}

.clear {
  position: absolute;
  top: -24px;
  right: 0;
  color: var(--color-red-primary);
}

.placeholder {
  flex: 1;
  color: var(--color-grey-dark);
}

.menuList {
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 8px;
  background-color: var(--color-white-primary);
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-1);
}

.valueContainer {
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.value {
  flex: 1;
}

.option {
  margin-top: 4px;
  padding: 8px;
  color: var(--color-black-primary);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: var(--color-blue-10);
  }
}

.noOption {
  margin: 7px 0 7px 16px;
  color: var(--color-grey-dark);
}

.tag {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 3px 4px 3px 0;
  padding: 7px 8px;
  color: var(--color-blue-primary);
  background-color: var(--color-blue-15);
  border-radius: 4px;
}

.removeContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  background-color: var(--color-blue-15);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  // &:hover {
  //   background-color: var(--color-white-40);
  // }

  // &:active {
  //   background-color: var(--color-white-60);
  // }
}

.removeIcon {
  width: 12px;
  height: 12px;
  color: var(--color-blue-primary);
}
