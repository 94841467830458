.preview {
  max-width: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.caption {
  margin-top: 12px;
  padding-left: 12px;
  border-left: 2px solid #dfe9f9;
  font-style: italic;
}
