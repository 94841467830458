.select {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 16px;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
  padding: 24px 0;
}

.title {
  margin-bottom: 24px;
  color: #5c738c;
}
