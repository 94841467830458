.tooltip {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: #021930;
  border-radius: 4px;
  width: max-content;
}

.text {
  color: var(--color-white-primary);
  max-width: 200px;
}
