.form {
  display: flex;
  flex-flow: column nowrap;
}

.field {
  margin-bottom: 32px;
}

.actions {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 8px;
}

.action {
  width: 100%;
}
