.page {
    padding-top: 32px;
}

.title {
    font-weight: 600;
}

.tabs {
    background-color: #FFFFFF;
    border-radius: 16px;
    margin-top: 18px;
}

.inner {
    padding: 16px;
    margin-top: 0px;
}

.action {
    color:#5C738C;
}