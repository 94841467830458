.iconWrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(17, 130, 246, 0.15);
}

.icon {
  width: 16px;
  height: 16px;
  color: #021930;
}
