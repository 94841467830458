.root {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 24px;
}

.webRoot {
  margin-top: 40px;
}

.inner {
  margin-top: 8px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 24px;
  background-color: rgba(17, 130, 246, 0.05);
}

.preview {
  max-width: 220px;
  object-fit: contain;
}
