.section {
  margin-top: 32px;
  display: flex;
  flex-flow: column nowrap;
}

.title {
  margin-bottom: 8px;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 12px;
  border: 1px solid #dfe9f9;
}

.layers {
  flex: 1;
  padding: 16px 8px;
  border-right: 1px solid #dfe9f9;
}

.layersTitle {
  margin-left: 8px;
  margin-bottom: 8px;
}

.main {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  max-width: 518px;
}

.properties {
  display: flex;
  flex-flow: column nowrap;
}

.propertiesHeader {
  margin-left: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.propertiesTitle {
  margin-left: 8px;
}

.options {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 16px;
}

.optionsHeader {
  margin-left: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.optionsTitle {
  margin-left: 8px;
}
