.page {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 24px;
  background-color: var(--color-white-primary);
  border-radius: 8px;
  border: 1px solid var(--color-grey-medium);
}

.toolbar {
  background-color: var(--color-white-primary);
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-grey-medium);
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
