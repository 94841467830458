.pagination {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #dfe9f9;
  padding: 16px;
}

.perPage {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.perPageText {
  margin-right: 16px;
  color: #5c738c;
}

.perPageSelect {
  width: 60px;
  height: 32px;
}

.controls {
  margin-left: 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.control {
  margin-left: 4px;
}

.controlsText {
  margin-right: 12px;
  color: #5c738c;
}
