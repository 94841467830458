.area {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 300px;
  padding: 16px;
  border-radius: 8px;
  border: 1px dashed #889ab1;
  background-color: transparent;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.areaIsOver {
  border-color: #1182f6;

  .text {
    opacity: 0;
  }
}

.text {
  color: #5c738c;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.items {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dfe9f9;
  border-radius: 8px;
  margin-top: 8px;
  padding: 11px 12px;
  background-color: var(--color-white-primary);
  transition: all 0.15s ease-out;

  &:first-child {
    margin-top: 0;
  }
}

.itemCorrect {
  padding: 10px 12px;
  border-color: #34cb73;

  .itemIconWrapper {
    background-color: #34cb73;
  }
}

.itemIncorrect {
  padding: 10px 12px;
  border-color: #f53149;

  .itemIconWrapper {
    background-color: #f53149;
  }
}

.itemText {
  flex: 1;
  margin-right: 8px;
  color: #021930;
}

.itemIconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.itemIcon {
  width: 24px;
  height: 24px;
}