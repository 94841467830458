.sortButton {
    display: flex;
    padding: 9px 12px 9px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 38px;
    border: 1px solid var(--color-grey-medium);
    background-color: var(--color-white-primary);
    color: #5C738C;
    cursor: pointer;

    &:hover {
        background-color: rgba(17, 130, 246, 0.1);
      }
}

.active {
    color: var(--color-white-primary);
    background-color: var(--color-blue-primary);
    cursor: default;

    &:hover {
        background-color: var(--color-blue-primary);
    }

}

.startIcon {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;

    svg {
        width: 18px;
        height: 18px;
    }
}

