.preview {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 16px;
  background-color: #f3f8ff;
  box-shadow: var(--shadow-elevation-2);
  margin-bottom: 24px;
}

.lg {
  width: 768px;
}

.sm {
  width: 388px;

  .inner {
    padding: 0;
  }
}

.header {
  padding: 12px;
  background-color: var(--color-white-primary);
  border-bottom: 1px solid #dfe9f9;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: space-between;
}

.logo {
  display: block;
  width: 57px;
}

.icon {
  width: 20px;
  height: 20px;
}

.toolbar {
  background-color: var(--color-white-primary);
  padding: 16px 24px;
  border-bottom: 1px solid #dfe9f9;
}

.inner {
  padding: 24px;
}

.theoryLessonInner {
  padding: 16px;
}