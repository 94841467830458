.select {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: var(--color-grey-light);
  border-radius: 12px;
  padding: 4px;
}

.selectedOverlay {
  position: absolute;
  top: 4px;
  background-color: var(--color-white-primary);
  width: 120px;
  height: 80px;
  border-radius: 8px;
  z-index: 1;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.option {
  position: relative;
  width: 120px;
  height: 80px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.optionSelected {
  .optionIcon {
    color: var(--color-blue-primary);
  }

  .optionTitle {
    color: var(--color-black-primary);
  }
}

.optionIcon {
  width: 28px;
  height: 28px;
  color: var(--color-grey-dark);
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.optionTitle {
  margin-top: 12px;
  color: var(--color-grey-dark);
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}
