.page {
  margin-top: 16px;
}

.sidebar {
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 324px;
  background-color: var(--color-white-primary);
  border-right: 1px solid #dfe9f9;
}

.header {
  padding: 16px 0;
  border-bottom: 1px solid #dfe9f9;
}

.lessonsBtn {
  margin-left: 9px;
}
