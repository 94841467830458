.page {
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  width: 668px;
}

.pluginPage{
  width: 100%;
  padding: 16px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header {
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--color-grey-medium);
  background: var(--color-white-primary);
}
