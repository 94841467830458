.answer {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.readOnlyAnswer {
  margin-left: 72px;
}

.orderWrapper {
  width: 28px;
  height: 28px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(17, 130, 246, 0.1);
}

.order {
  color: #5c738c;
}

.inner {
  flex: 1;
}

.text {
  width: 100%;
  margin: 0 12px;
}

.image {
  margin: 0 12px;
}

.comment {
  width: 100%;
  margin: 8px 12px 0 12px;
}

.checkboxWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.caption {
  margin-right: 8px;
  color: #5c738c;
}
