.nameCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
}

.name {
  margin-left: 16px;
  white-space: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 80px);
}

.copyIcon {
  height: 16px;
  width: 16px;
  margin-left: 4px;
  color: var(--color-blue-primary);
}

.coursesCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.coursePreview {
  width: 32px;
  height: 32px;
  margin-right: 2px;
}

.courseTooltip {
  color: var(--color-white-primary);
}

.statusCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.schoolCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.schoolTrigger {
  margin-left: 2px;

  &:first-child {
    margin-left: 0;
  }
}
