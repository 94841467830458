.cell {
  flex: 1 0 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
}

.title {
  color: #021930;
  margin-bottom: 4px;
}

.count {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.countText {
  color: #5c738c;
}

.countIcon {
  margin-left: 2px;
  width: 16px;
  height: 16px;
  color: #889ab1;
  transition: all 0.15s ease-out;
}

.expandedIcon {
  transform: rotate(180deg);
}
