.layout {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  background-color: #f2f6fd;
}

.inner {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
}

.actionText {
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.15px;
  margin-right: 12px;
  color: #5C738C;
}

.tabs {
  width: 100%;
}

.inner {
  display: flex;
  // justify-content: center;
  margin-bottom: 56px;
  margin-top: 14px;
}

.tabsText {
  margin: 0;
}

.actions {
  display: none;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 24px;
  border-bottom: 1px solid #DFE9F9;
  margin-bottom: 14px;
}