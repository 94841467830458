.info {
  position: absolute;
  bottom: 14px;
  left: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.color {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.inner {
  flex: 1;
  margin-left: 8px;
}

.text {
  color: #5c738c;
}

.value {
  color: #021930;
}
