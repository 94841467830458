.question {
  flex: 1;
  border-radius: 16px;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  padding: 24px 0 16px 0;

  &:first-child {
    margin-top: 0;
  }
}

.readOnlyQuestion {
  margin-left: 72px;
}

.title {
  margin: 0 96px 8px 96px;
  color: #5c738c;
}

.settings {
  display: flex;
  flex-flow: column nowrap;
  padding: 24px;
}

.divider {
  margin: 16px 96px 24px 96px;
  height: 1px;
  background-color: #dfe9f9;
}

.select {
  margin: 0 96px 8px 96px;
}

.selectTitle {
  color: #5c738c;
  margin-bottom: 8px;
}

.commentSection {
  display: flex;
  flex-flow: column nowrap;
}

.comment {
  margin: 0 96px 8px 96px;
}
