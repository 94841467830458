.root {
  margin-top: 8px;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 16px;
  border: 1px solid var(--color-grey-medium);
  background-color: var( --color-white-primary);
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.headerSwitch {
  margin-right: 16px;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-top: 24px;
  border-radius: 12px;
  border: 1px solid var(--color-grey-medium);
  background: var(--color-blue-5);
}

.upload {
  width: 308px;
  height: 320px;
  padding: 10px 12px;
}