.section {
  margin-top: 16px;
}

.titleCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.statusCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.progressCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.preview {
  width: 40px;
  height: 40px;
}

.title {
  margin-left: 16px;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 6px;
  margin-bottom: 8px;
}

.emptyState {
  margin: 24px 0;
  border: 0;
}

.action {
  margin-left: auto;
  margin-right: 16px;
}