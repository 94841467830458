.cell {
  flex: 1 0 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: -16px;
}

.lessonsStructureCell {
  margin-left: 0;
}

.iconWrapper {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--color-blue-10);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.icon {
  width: 16px;
  height: 16px;
  color: #5c738c;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
}

.title {
  color: #021930;
}
