.section {
  margin-top: 16px;
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 16px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.headerText {
  margin-left: 16px;
}

.text {
  margin-top: 16px;
  color: #5c738c;
}

.styles {
  margin-top: 8px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 8px;
}

.style {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ebebeb;
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 72px;
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 6.67%,
      rgba(0, 0, 0, 0.39) 13.33%,
      rgba(0, 0, 0, 0.37) 20%,
      rgba(0, 0, 0, 0.34) 26.67%,
      rgba(0, 0, 0, 0.31) 33.33%,
      rgba(0, 0, 0, 0.27) 40%,
      rgba(0, 0, 0, 0.22) 46.67%,
      rgba(0, 0, 0, 0.18) 53.33%,
      rgba(0, 0, 0, 0.13) 60%,
      rgba(0, 0, 0, 0.09) 66.67%,
      rgba(0, 0, 0, 0.06) 73.33%,
      rgba(0, 0, 0, 0.03) 80%,
      rgba(0, 0, 0, 0.01) 86.67%,
      rgba(0, 0, 0, 0) 93.33%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.styleCheck {
  position: absolute;
  top: 4px;
  right: 4px;
}

.styleCheckIconWrapper {
  border-color: #dfe9f9;
}

.stylePreview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 12px;
}
