.tag {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 8px 0; 
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1182F626;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 4px;
}

.icon {
  height: 14px;
  width: 14px;
  color: #5C738C;
}

.tagText {
  color: #5C738C;
}
