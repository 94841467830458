.lesson {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  background-color: #fff;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:last-child {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}

.title {
  flex: 1;
  margin-left: 12px;
}
