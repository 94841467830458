.block {
  margin-top: 24px;

  &:first-child {
    margin-top: 0;
  }
}

.textBlock {
  margin-top: 8px;
}
