.switch {
  position: relative;
  display: inline-block;

  transition: all 0.15s ease-in-out;
}

.switchReadOnly {
  .field {
    cursor: default;
  }
}

.switchLarge {
  width: 40px;
  height: 24px;

  .field {
    &:checked ~ .toggle {
      width: 20px;
      height: 20px;
      left: 18px;
    }
  }

  .toggle {
    width: 14px;
    height: 14px;
    top: 5px;
    left: 5px;
  }
}

.switchSmall {
  width: 32px;
  height: 18px;

  .field {
    &:checked ~ .toggle {
      width: 14px;
      height: 14px;
      left: 16px;
    }
  }

  .toggle {
    width: 10px;
    height: 10px;
    top: 4px;
    left: 4px;
  }
}

.switchDisabled {
  opacity: 0.48;
  pointer-events: none;
}

.field {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;

  &:checked ~ .body {
    background-color: var(--color-blue-primary);
  }

  &:checked ~ .toggle {
    width: 20px;
    height: 20px;
    background-color: var(--color-white-primary);
    top: 2px;
    left: 18px;
  }
}

.body {
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-10);
  border-radius: 56px;
  transition: all 0.15s ease-out;
}

.toggle {
  position: absolute;
  z-index: 2;
  background-color: var(--color-grey-dark);
  border-radius: 50%;
  box-shadow: var(--shadow-elevation-1);
  transition: all 0.15s ease-out;
}
