.upload {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #f2f6fd;
  border: 1px solid #dfe9f9;
  border-radius: 12px;
  padding: 40px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:not(.uploadWithValue):hover {
    background-color: rgba(17, 130, 246, 0.1);
    border-color: #1182f6;

    .icon,
    .text {
      color: #1182f6;
    }
  }

  &:hover {
    .action {
      opacity: 1;
    }
  }
}

.uploadWithValue {
  padding: 0;
}

.uploadHaveError {
  border-color: var(--color-red-primary);
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.main {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.icon {
  width: 20px;
  height: 20px;
  color: #5c738c;
}

.text {
  margin-left: 12px;
  color: #5c738c;
}

.tags {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.tag {
  background-color: var(--color-white-primary);
  padding: 4px 8px;
  border-radius: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.tagText {
  color: #5c738c;
}

.preview {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.actionOverlay {
  opacity: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-black-60);
  border-radius: 12px;
  z-index: 1;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  height: 56px;
  width: 56px;
  transform: translate(-50%, -50%);
}

.action {
  opacity: 0;
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 2;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}
