.table {
  display: flex;
  flex-flow: column nowrap;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 16px;
}

.headerCheck {
  margin-right: 16px;
}

.sortableCell {
  cursor: pointer;
}

.headerText {
  color: #889ab1;
}

.sortingIcon {
  color: #889ab1;
}

.body {
  display: flex;
  flex-flow: column nowrap;
}

.row {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 64px;
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-grey-medium);
  cursor: pointer;
  transition: background-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    .actions {
      opacity: 1;
      visibility: visible;
    }
  }
}

.rowCheck {
  margin-right: 16px;
}

.actions {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 2px;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
}

.action {
  margin-left: 4px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
}

.actionTooltip {
  color: var(--color-white-primary);
}
