.properties {
  display: flex;
  flex-flow: column nowrap;
}

.propertiesRow {
  display: flex;
  flex-flow: row nowrap;
  column-gap: 24px;
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }
}

.propertiesColumn {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 12px;
}
