.propertyReadOnly {
  width: max-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 36px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  padding: 4px 8px;
}

.value {
  color: #5c738c;
}

.color {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  margin-right: 4px;
  border: 1px solid #dfe9f9;
}
