.icon {
  width: 18px;
  height: 18px;
  margin: 8px 4px;
  color: var(--color-grey-dark);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    color: var(--color-blue-primary);
  }
}

.iconActive {
  color: var(--color-blue-primary);
}
