.root {
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  width: 352px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-1);
}

.months {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.month {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.table {
  display: flex;
  flex-flow: column nowrap;
  border-spacing: 0;
  border-color: unset;
}

.tbody {
  display: flex;
  flex-flow: column nowrap;
}
