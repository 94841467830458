.header {
  display: flex;
  flex-flow: row nowrap;
  padding: 12px 16px;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey-medium);
}

.emailFromContainer {
  display: flex;
  flex-flow: column nowrap;
  width: 300px;
}

.emailFrom {
  color: #5c738c;
}

.date {
  color: #5c738c;
  width: 150px;
  display: flex;
  justify-content: flex-end;
}

.content {
  padding: 16px;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
}

.answer {
  display: flex;
  flex-flow: column nowrap;
}

.answerTitle {
  color: #5c738c;
}

.answerText {
  margin-top: 4px;
}
