.tag {
  width: max-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 3px 4px;
  border-radius: 4px;
}

.info {
  background-color: rgba(98, 60, 234, 0.15);

  .text {
    color: #623cea;
  }
}

.warning {
  background-color: #fcae2f;

  .text {
    color: #fff;
  }
}

.success {
  background-color: rgba(52, 203, 115, 0.15);

  .text {
    color: #34cb73;
  }
}

.text {
  letter-spacing: 0.15px;
}
