.lines {
  position: absolute;
  top: 0;
  right: 0;;
  bottom: 0;
  left: 0;
}

.inner {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
}