.block {
  width: 100%;
}

.readOnlyBlock {
  margin-left: 72px;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.itemOrder {
  background-color: #623cea;
  width: 12px;
  height: 2px;
  border-radius: 24px;
}

.itemText {
  margin-left: 8px;
  flex: 1;
}
