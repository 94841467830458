.tag {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 4px 8px 4px 4px;
  border-radius: 4px;
  width: max-content;
}

.draft {
  .icon {
    color: #5c738c;
  }
}

.archived {
  .icon {
    color: #623cea;
  }
}

.published {
  .icon {
    color: #34cb73;
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  flex: 1;
  margin-left: 4px;
  color: #021930;
}
