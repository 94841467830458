.emptyLayers {
  margin: 80px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.icon {
  width: 56px;
  height: 56px;
  color: #5c738c;
}

.title {
  margin-top: 16px;
}

.text {
  margin-top: 8px;
}
