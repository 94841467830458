.page {
  margin-top: 16px;
}

.inner {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 16px;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  border: 1px solid #dfe9f9;
  padding: 24px 0;
}

.divider {
  margin: 16px 74px 24px 74px;
  height: 1px;
  background-color: #dfe9f9;
}

.main {
  margin: 0 74px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
