.module {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #dfe9f9;
  margin-top: 8px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:not(.moduleDisabled):hover {
    .action {
      opacity: 1;
    }

    .progress {
      opacity: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}

.moduleOpened {
  .countIcon {
    transform: rotate(-180deg);
  }
}

.moduleDisabled {
  background-color: #f2f6fd;

  .title {
    color: #5c738c;
  }
}

.header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px;
}

.title {
  margin-left: 12px;
  color: #021930;
}

.preview {
  width: 40px;
  height: 40px;
}

.inner {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-top: 1px solid #dfe9f9;
}

.content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.progress {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.completedIcon,
.lockIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}

.completedIcon,
.lockIcon {
  width: 20px;
  height: 20px;
}

.lockIcon {
  color: #5c738c;
}
