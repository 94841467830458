.box {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 7px;
  width: max-content;
  height: 32px;
  background-color: var(--color-white-primary);
  border: 1px solid var(--color-grey-medium);
  border-radius: 4px;
  outline: none;
  transition: all 0.15s ease-out;

  &:hover {
    border-color: var(--color-blue-primary);
  }
}

.boxChecked {
  background-color: var(--color-blue-10);
  border-color: var(--color-blue-primary);

  .label {
    color: var(--color-blue-primary);
  }
}

.boxWithStartAdornment {
  padding: 5px 7px 5px 3px;
}

.boxWithEndAdornment {
  padding: 5px 3px 5px 7px;
}

.field {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  opacity: 0;
}

.inner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.startAdornment {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  width: 20px;
  height: 20px;
}

.endAdornment {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  width: 20px;
  height: 20px;
}

.label {
  color: var(--color-grey-dark);
}
