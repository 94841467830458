.editable {
  display: block;
  font-family: 'Inter', sans-serif;
  color: #021930;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: text;

  &::placeholder {
    color: #889ab1;
  }
}

.editableMultiline {
  resize: none;

  &.heading1 {
    height: 44px;
  }

  &.heading2 {
    height: 32px;
  }

  &.heading3 {
    height: 24px;
  }

  &.text {
    height: 26px;
  }
}

.editableReadOnly {
  cursor: default;
}

.heading1 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
}

.heading2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}

.heading3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.text {
  font-size: 16px;
  line-height: 26px;
}
