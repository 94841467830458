.info {
  max-width: 328px;
  flex: 1;
  margin-top: 16px;
  margin-left: 12px;
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white-primary);
  border: 1px solid #dfe9f9;
  border-radius: 16px;
}

.item {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.itemLabel {
  color: #5c738c;
}

.itemValue {
  margin-top: 2px;
  color: #021930;
}

.itemValuePromocode {
  color: #a18af2;
}

.itemValueDirect {
  color: #70b4fa;
}
