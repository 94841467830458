.field {
  padding-right: 84px;
}

.switcher {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 72px;
  height: 36px;
  border-radius: 4px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid var(--color-blue-15);
    border-radius: 4px;
    content: "";
  }
}

.item {
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--color-grey-medium);
  cursor: pointer;
  transition: all .15s ease-out;

  &:not(.itemActive):hover {
    background-color: var(--color-blue-light);

    .text {
      color: var(--color-white-primary);
    }
  }

  &:not(.itemActive):active {
    background-color: var(--color-blue-medium);

    .text {
      color: var(--color-white-primary);
    }
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.itemActive {
  background-color: var(--color-blue-primary);

  .text {
    color: var(--color-white-primary);
  }
}

.text {
  color: var(--color-blue-primary);
}