.button {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #f2f6fd;
  border: 1px solid #dfe9f9;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.text {
  color: #5c738c;
}
