.card {
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe9f9;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
}

.dateLabel {
  color: #5c738c;
}

.dateText {
  color: #021930;
}

.main {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.infoItems {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.info {
  width: 158px;
  display: flex;
  flex-flow: column nowrap;
  margin-left: 12px;

  &:first-child {
    margin-left: 0;
  }
}

.infoLabel {
  color: #5c738c;
}

.infoValue {
  margin-top: 2px;
  color: #021930;
}

.infoValuePromocode {
  color: #a18af2;
}

.infoValueDirect {
  color: #70b4fa;
}

.infoValueProSubscription {
  color: #623cea;
}

.infoValueFree {
  color: #34cb73;
}

.infoValueTest {
  color: #fcae2f;
}
