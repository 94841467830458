.select {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.selectDisabled {
  .item {
    pointer-events: none;
  }
}

.item {
  margin-left: 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 6px 8px 6px 4px;
  border: 1px solid #dfe9f9;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.15s ease-out;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border-color: #1182f6;
  }
}

.itemActive {
  background-color: rgba(17, 130, 246, 0.1);
  border-color: #1182f6;

  .text,
  .icon {
    color: #1182f6;
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: #5c738c;
}

.text {
  margin-left: 4px;
  color: #5c738c;
}
