.page {
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
}

.inner {
  display: flex;
  flex-flow: column nowrap;
  width: 720px;
}

.main {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 16px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}