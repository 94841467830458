.bar {
  margin-left: 4px;
}

.inputRange {
  position: relative;
  width: 100%;
}

.inputRangeDisabled {
  .slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  .track {
    background: #eeeeee;
  }
}

.minLabel,
.maxLabel,
.valueLabel {
  display: none;
}

.sliderContainer {
  position: absolute;
  top: 50%;
  width: 16px;
  height: 16px;
  margin-top: -8px;
}

.slider {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  transform: translateX(-50%);

  &:active {
    transform: scale(1.3);
    transform: translateX(-50%);
  }
}

.track {
  position: relative;
  height: 4px;
  background-color: var(--color-white-40);
  border-radius: 8px;
  cursor: pointer;
  transition: left 0.15s ease-out, width 0.15s ease-out;
}

.trackActive {
  height: 100%;
  background-color: var(--color-white-primary);
  border-radius: 8px;
  position: realtive;
}
