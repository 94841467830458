.settings {
  display: flex;
  flex-flow: column nowrap;
}

.divider {
  margin: 16px 104px 24px 104px;
  height: 1px;
  background-color: #dfe9f9;
}
