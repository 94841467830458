.day {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.15s ease-out;
  cursor: pointer;

  &:not(.daySelected):hover {
    background-color: rgba(17, 130, 246, 0.1);
  }
}

.dayToday {
  background-color: rgba(17, 130, 246, 0.05);
}

.daySelected {
  background-color: #1182f6;
}
