.propertyReadOnly {
  width: max-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 36px;
  border: 1px solid #dfe9f9;
  background-color: #fff;
  padding: 4px 8px;
}

.fieldTrigger {
  position: relative;
  display: flex;
  max-width: 100%;

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 100px;
    max-width: 100px;
    border: 2px solid #ffffff;
    opacity: 1;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    border-radius: 50%;
    transition: opacity 0.15s ease-out;
    word-break: break-word;
  }
}

.value {
  margin-left: 4px;
  color: #5c738c;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.missingValue {
  font-style: italic;
  color: #021930;
}

.label {
  color: #5c738c;
  margin-right: 4px;
}
