.block {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  padding: 12px;
  border: 1px solid #dfe9f9;
  border-radius: 8px;
}

.readOnlyBlock {
  margin-left: 72px;
}
