.answer {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 261px;
  width: 348px;
  background-color: var(--color-white-primary);
  border-radius: 16px;
  padding: 6px;
  margin-bottom: 24px;
  border: 1px solid #dfe9f9;
  cursor: pointer;
  transition: background-color,
    border-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:hover {
    border-color: var(--color-blue-primary);
  }

  &:last-child {
    margin-bottom: 0;
  }

}

.pluginImage {
  height: 232px;
}

.readOnlyAnswer {
  cursor: default;
  &:hover {
    border: 1px solid #dfe9f9;
    transition: background-color,
    border-color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  }
}

.answerSelected {
  background-color: rgba(17, 130, 246, 0.1);
}

.answerCorrect {
  background-color: #f7f9fd;
  padding: 5px;
  border: 2px solid #34cb73;

  .iconWrapperCorrect {
    opacity: 1;
  }
}

.answerIncorrect {
  background-color: #f7f9fd;
  border: 2px solid #f53149;
  padding: 5px;

  .iconWrapperIncorrect {
    opacity: 1;
  }
}

.answerSubmitted {
  pointer-events: none;
}

.answerWithComment {
  width: 100%;
}

.img {
  width: 336px;
  max-height: 100%;
  object-fit: contain;
  border-radius: 11px;
}

.iconWrapper {
  opacity: 0;
  position: absolute;
  bottom: -8px;
  right: -8px;
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon {
  width: 24px;
  height: 24px;
}

.comment {
  position: relative;
  margin: 16px 0 0 36px;
  padding-left: 12px;
  color: rgba(#021930, 0.8);
}

.commentCorrect {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 8px;
    background-color: #34cb73;
  }
}

.commentIncorrect {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    border-radius: 8px;
    background-color: #f53149;
  }
}
