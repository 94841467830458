.tree {
  width: 324px;
  background-color: var(--color-white-primary);
  border-right: 1px solid #dfe9f9;
  position: sticky;
  top: 0;
}

.addBtn {
  margin-top: 4px;
}
