.button {
  width: max-content;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  line-height: 1;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: background-color, color 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

.startIcon {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.endIcon {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.primary {
  color: var(--color-white-primary);
  background-color: var(--color-blue-primary);

  &:hover {
    background-color: var(--color-blue-medium);
  }

  &:active {
    background-color: var(--color-blue-dark);
  }

  &:disabled {
    background-color: var(--color-blue-32);
  }
}

.secondary {
  color: var(--color-blue-primary);
  background-color: var(--color-blue-15);

  &:hover {
    color: var(--color-white-primary);
    background-color: #0e68c5;
  }

  &:active {
    color: var(--color-white-primary);
    background-color: #0a4e94;
  }

  &:disabled {
    background-color: var(--color-blue-32);
  }
}

.outlined {
  color: var(--color-blue-primary);
  background-color: transparent;
  border: 1px solid #dfe9f9;

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
  }

  &:active {
    color: #0a4e94;
    background-color: rgba(17, 130, 246, 0.15);
  }

  &:disabled {
    opacity: 0.32;
  }
}

.link {
  color: var(--color-blue-primary);
  background-color: transparent;

  &:hover {
    background-color: var(--color-blue-10);
  }

  &:active {
    color: #0a4e94;
    background-color: var(--color-blue-15);
  }

  &:disabled {
    background-color: var(--color-blue-15);
    opacity: 0.32;
  }
}

.ghost {
  color: #5c738c;
  background-color: transparent;

  &:hover {
    background-color: rgba(17, 130, 246, 0.1);
  }

  &:active {
    color: #021930;
    background-color: rgba(17, 130, 246, 0.1);
  }

  &:disabled {
    opacity: 0.32;
  }
}

.sm {
  padding: 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 6px;

  &.outlined {
    padding: 6px;
  }

  .buttonIcon,
  .startIcon,
  .endIcon {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .startIcon {
    margin-right: 4px;
  }

  .endIcon {
    margin-left: 4px;
  }
}

.md {
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border-radius: 6px;

  &.outlined {
    padding: 9px;
  }

  .buttonIcon,
  .startIcon,
  .endIcon {
    width: 18px;
    height: 18px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .startIcon {
    margin-right: 6px;
  }

  .endIcon {
    margin-left: 6px;
  }
}

.lg {
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;

  &.outlined {
    padding: 11px;
  }

  .buttonIcon,
  .startIcon,
  .endIcon {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .startIcon {
    margin-right: 8px;
  }

  .endIcon {
    margin-left: 8px;
  }
}
