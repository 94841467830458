.form {
  display: flex;
  flex-flow: column nowrap;
}

.field {
  margin-bottom: 32px;
}

.button {
  width: 100%;
  font-size: 16px;
  padding: 12px;
}
