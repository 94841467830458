.info {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.valueLow {
  color: #f53149;
}

.valueMedium {
  color: #fcae2f;
}

.valueHigh {
  color: #34cb73;
}

.text {
  color: #5c738c;
}
