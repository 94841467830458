.section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 24px 16px;
  background-color: rgba(252, 174, 47, 0.15);
  border: 1px solid #fcae2f;
  border-radius: 12px;
  margin-bottom: 24px;
}

.icon {
  color: #fcae2f;
  margin-right: 16px;
}
