.row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
}

.cell {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
}

.text {
  color: #889ab1;
}
