.block {
  width: 100%;
}

.readOnlyBlock {
  margin-left: 72px;
}

.blockTitle {
}

.blockText {
  width: 100%;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.itemOrder {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(98, 60, 234, 0.1);
}

.itemOrderText {
  color: #623cea;
}

.itemPropertyType {
  width: 200px;
}

.itemText {
  flex: 1;
  margin-left: 8px;
}
