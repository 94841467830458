.nameCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.name {
  margin-left: 16px;
}

.nameCaption {
  color: #889ab1;
}

.statusCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.schoolCell {
  flex: 1 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
