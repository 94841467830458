.page {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  align-items: center;
  background-color: var(--color-grey-light);
}

.container {
  flex: 1;
}

.inner {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.title {
  margin-top: 40px;
  font-size: 35px;
  line-height: 48px;
  font-weight: 700;
  color: var(--color-black-primary);
  text-align: center;
}

.text {
  margin-top: 16px;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-grey-dark);
  text-align: center;
}

.button {
  margin-top: 40px;
}

@media all and (min-width: 768px) {
  .title {
    margin-top: 48px;
    font-size: 72px;
    line-height: 80px;
  }

  .text {
    margin-top: 24px;
    font-size: 16px;
    line-height: 28px;
  }

  .button {
    margin-top: 48px;
    padding: 16px 20px !important;
  }
}

@media all and (min-width: 1440px) {
  .icon {
    width: 1036px;
  }
}