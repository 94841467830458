:global {

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  html,
  body {
    height: 100%;
  }

  body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  ul,
  ol {
    padding: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  img {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
    padding: 0;
  }
}
